import React, { useEffect, useState } from 'react'
import HeaderMain from '../../Compoents/HeaderMain'
import FooterWhite from '../../Compoents/FooterWhite'
import BreadcrumbArea from '../../ShearOne/BreadcrumbArea'
import { useParams } from 'react-router-dom'
import axios from 'axios'

const TrainingDetails = () => {
  const breadcrumbLinks = [
    { path: '/', label: 'Home' },
    { label: 'Training Details', active: true }
  ];
  return (
    <>
    <HeaderMain/>
    <main>
   
   <BreadcrumbArea 
     title="Training Details" 
     backgroundImage="/assets/imgs/breadcrumb/page-header-1.png" 
     links={breadcrumbLinks} 
   />

   
</main>

<Trainingdata/>

<section class="footer__cta footer__cta-bottom-up">
    <div class="container">
        <div class="row mb-minus-30">
            <div class="col-md-6">
                <div class="footer__cta-item mb-30 theme-bg-primary d-flex flex-lg-row flex-column align-items-lg-center">
                    <div class="footer__cta-item-icon d-flex align-items-center justify-content-center">
                        <img class="img-fluid" src="/assets/imgs/footer__cta/footer__cta-item-1.png" alt="icon not found"/>
                    </div>

                    <div class="footer__cta-item-text">
                        <h4 class="color-white mb-15 mb-10">Expert Eye Care</h4>
                        <p class="color-white mb-0">Et purus duis sollicitudin dignissim habitant. Egestas nulla quis venenatis cras sed eu massa eu faucibus</p>
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="footer__cta-item mb-30 theme-bg-glow d-flex flex-lg-row flex-column align-items-lg-center">
                    <div class="footer__cta-item-icon d-flex align-items-center justify-content-center">
                        <img class="img-fluid" src="/assets/imgs/footer__cta/footer__cta-item-2.png" alt="icon not found"/>
                    </div>

                    <div class="footer__cta-item-text">
                        <h4 class="color-white mb-15 mb-10">Eye Health Provider</h4>
                        <p class="color-white mb-0">Et purus duis sollicitudin dignissim habitant. Egestas nulla quis venenatis cras sed eu massa eu faucibus</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
    <FooterWhite/>

    </>
  )
}

export default TrainingDetails



export const Trainingdata = () => {
    const { id } = useParams(); // Extract the id parameter from the URL
  const [blog, setBlog] = useState("");


  const [data, setData] = useState([]);
  useEffect(() => {
    axios.post(`https://api.squaremedtech.com/api/easy/clinical/learning/detail/${id}`)
      .then((response) => {
        setBlog(response.data);
      })
      .catch((error) => {
        console.error('Error fetching blog details:', error);
      });
  }, [id]);

    return (
        <>
            <section class="blog section-space">
              <div>
    
      {blog ? ( 
          <>
          <div class="container">
            <div class="row">
                <div class="col-xl-8">
                    <div class="blog__details">
                        <div class="blog__details-thumb mb-20">
                            <img src={blog?.data?.image} class="img-fluid" alt="image not found"/>
                        </div>

                        <ul class="blog__details-meta mb-20">
                            <li><a href="#"><i class="fa-regular fa-user"></i>By admin</a></li>
                            <li><i class="fa-regular fa-calendar-days"></i> {blog?.data?.sub_title}</li>
                        </ul>

                        <div class="blog__details-content">
                            <h2>{blog?.data?.title}</h2>

                            <blockquote>
                                <h5>{blog?.data?.heading}</h5>
                            </blockquote>

                            <div class="col-md-12">
                                <div class="text-holder">
                                    {/* <p>{blog?.data?.description}</p> */}
                                    <p dangerouslySetInnerHTML={{ __html: blog?.data?.description }}></p>

                                </div>

                            </div>
                           
                        </div>
                    </div>
                </div>

               
            </div>
        </div>
            
          </>
        ) : (
            <div className="spinner-container">
          <span className="spinner"></span>
        </div>
        )}

    </div>
        
    </section>
        </>
    );
};
