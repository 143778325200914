import React from 'react'
import HeaderMain from '../../../Compoents/HeaderMain'
import BreadcrumbArea from '../../../ShearOne/BreadcrumbArea';
import FooterWhite from '../../../Compoents/FooterWhite';
import Appoinment from '../../Home/HomeInner/Appoinment';

export const HowWeWork = () => {
    const breadcrumbLinks = [
        { path: '/', label: 'Home' },
        { label: 'How We Work', active: true }
      ];
  return (
 <>
    <HeaderMain/>
    <BreadcrumbArea 
     title="How We Work" 
     backgroundImage="/assets/imgs/breadcrumb/page-header-1.png" 
     links={breadcrumbLinks} 
   />
<HowWeWorkAbout/>
<Appoinment/>
<FocusPoint/>
   <FooterWhite/>
   </>
  )
}
 

export const HowWeWorkAbout = () => {
  return (
 <>
    <section class="doctor-details section-space">
        <div class="container">
            <div class="row">
                <div class="col-lg-4">
                    <div class="doctor-details__media mb-40">
                        <img src="./assets/imgs/doctor-details/HowWeWork.jpg" class="img-fluid" alt="img not found"/>
                    </div>
                  
                </div>
                <div class="col-lg-8">
                    <div class="doctor-details__content mt-md-50 mt-sm-40 mt-xs-40">
                        <div class="doctor-details__content-future mb-30">
                            <h2 class="mb-20 title-animation">As your clinical service partner, our dedicated expert team will:</h2>
                          
                            <ul class="doctor-details__content-future__lst p-3">
                                <li>Develop a clinical and regulatory-compliant strategy for your medical device</li>
                                <li>Create a comprehensive strategic plan and budget overview</li>
                                <li>Ensure your study meets all ISO 14155 and Notified Body (NB) compliance standards</li>
                                <li>Assist in preparing or reviewing your clinical trial protocol</li>
                                <li>Implement and manage your Electronic Data Capture System (EDC)</li>
                                <li>Assist in the selection and communication with study sites</li>
                                <li>Educate and train staff on Good Clinical Practice (GCP)</li>
                                <li>Conduct monitoring visits, including site initiation and close-out visits</li>
                                <li>Provide data management support</li>
                                <li>Oversee study progress and manage study sites</li>
                                <li>Assist with safety reporting</li>
                                <li>Organize and support a Data Safety Monitoring Board (DSMB) or Clinical Events Committee (CEC) when necessary</li>
                                <li>Ensure the clinical study is completed on time and in compliance with regulations</li>
                                <li>Analyze the data and write your clinical evaluation and investigation report</li>

                            </ul>
                            <p className='py-5'><b>We prioritize ethics, scientific validation, and compliance, ensuring adherence to GCP (ISO 14155) standards through our ISO 9001 and ISO 14155 compliant Quality Management System (QMS).</b></p>
                        </div>

                   

                    </div>
                </div>
            </div>
        </div>
    </section>
 </>
  )
}


export const FocusPoint = () => {
    return (
      <>
          <section class="footer__cta footer__cta-bottom-up">
      <div class="container">
          <div class="row mb-minus-30">
              <div class="col-md-6">
                  <div class="footer__cta-item mb-30 theme-bg-primary d-flex flex-lg-row flex-column align-items-lg-center">
                      <div class="footer__cta-item-icon d-flex align-items-center justify-content-center">
                          <img class="img-fluid" src="assets/imgs/footer__cta/footer__cta-item-1.png" alt="icon not found"/>
                      </div>
  
                      <div class="footer__cta-item-text">
                          <h4 class="color-white mb-15 mb-10">We Provide Consultations for:  </h4>
                          <ul class="color-white mb-0">
                          <li>Technical Documentation</li>
                            <li>Post Market Activities</li>
                            <li>Product Registrations</li>
                          </ul>
  
                      </div>
                  </div>
              </div>
  
              <div class="col-md-6">
                  <div class="footer__cta-item mb-30 theme-bg-glow d-flex flex-lg-row flex-column align-items-lg-center">
                      <div class="footer__cta-item-icon d-flex align-items-center justify-content-center">
                          <img class="img-fluid" src="assets/imgs/footer__cta/footer__cta-item-2.png" alt="icon not found"/>
                      </div>
  
                      <div class="footer__cta-item-text">
                          <h4 class="color-white mb-15 mb-10">We Provide Consultations for:  </h4>
                          <ul class="color-white mb-0">
                          <li>Clinical Evaluation</li>
                            <li>Clinical Investigation</li>
                            <li>EU MDR/FDA/NMPA Consulting</li>
                          </ul>
  
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </section>
      </>
    )
  }

