import { BrowserRouter, useRoutes } from "react-router-dom";
import Reoute from "./View/Routes";

function App() {
  const Routes = () => {
    return useRoutes(Reoute);
  };

  return (
    <BrowserRouter>
    <Routes />
  </BrowserRouter>
  );
}

export default App;
