import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
 <>
    <footer>
    <section class="footer-2__area-common theme-bg-color-900 overflow-hidden" style={{ backgroundImage: 'url(/assets/imgs/footer-2/background.png)' }}>
        <div class="container">
            <div class="row mb-minus-50">
                <div class="col-lg-3 col-md-6">
                    <div class="footer-2__widget footer-2__widget-item-1">
                        <div class="footer-2__logo mb-30 mb-xs-25">
                            <a >
                             <img src="/assets/imgs/logo/Logo_white.png" alt="logo not found" style={{maxWidth:"200px"}}/>
                            </a>
                        </div>
                        <div class="footer-2__content">
                        <p class="mb-0">SQUARE MEDICAL TECHNOLOGY (SHENZHEN) CO., LTD</p>
                        </div>
                        <div class="footer-2__social mt-30 mt-xs-30">
                            <a href="https://www.instagram.com/"><i class="fa-brands fa-instagram"></i></a>
                            <a href="https://www.facebook.com/"><i class="fab fa-facebook-f"></i></a>
                            <a href="https://twitter.com/">
                                <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.0596 6.77295L15.8879 -0.00195312H14.5068L9.44607 5.8806L5.40411 -0.00195312H0.742188L6.85442 8.89352L0.742188 15.998H2.12338L7.4676 9.78587L11.7362 15.998H16.3981L10.0593 6.77295H10.0596ZM8.16787 8.97189L7.54857 8.0861L2.62104 1.03779H4.74248L8.71905 6.726L9.33834 7.61179L14.5074 15.0056H12.386L8.16787 8.97223V8.97189Z" fill="white"/>
                                </svg>
                            </a>
                            <a href="https://www.linkedin.com/"><i class="fa-brands fa-linkedin-in"></i></a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-2 col-6">
                    <div class="footer-2__widget footer-2__widget-item-2">
                        <div class="footer-2__widget-title">
                            <h4>Usefull Link</h4>
                        </div>
                        <div class="footer-2__link">
                            <ul>
                                <li><Link to="/square-medtech"  >About Us</Link></li>
                                <li><Link to="/therapeutic-specialties" >Therapeutic Specialties  </Link></li>
                                <li><Link to="/blog" >	Medical Device Manufacturers </Link></li>
                                <li><Link to="/faq" >Pharmaceutical & Biotech industry</Link></li>
                                <li><Link to="/blog" >Blogs</Link></li>
         
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-6">
                    <div class="footer-2__widget footer-2__widget-item-3">
                        <div class="footer-2__widget-title">
                            <h4>Our Office</h4>
                        </div>

                        <div class="footer-2__link footer-2__link-location">
                            <ul>
                            <li><a href="tel:+8619811976744"><i class="fa-solid fa-phone"></i> +86 19811976744</a></li>
                                <li><a href="mailto:info@squaremedtech.com"><i class="fa-solid fa-envelope"></i> info@squaremedtech.com</a></li>
                                    <li><a href="https://maps.app.goo.gl/mMXm8BncfQXDwt6XA"><i class="fa-solid fa-location-dot"></i>Address: 436, Building D, No. 48 Gaofu Center, Gonghe Industrial Road, Xixiang Street, Bao'an District, Shenzhen, Guangdong, China <br/> Zip Code: 518000</a></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="footer-2__widget footer-2__widget-item-4">
                        <div class="footer-2__widget-title">
                            <h4>are you ready to start ?</h4>
                        </div>

                        <div class="footer-2__subscribe-content">
                            <p class="mb-30 mb-xs-25 color-white">For more information about our services or to discuss potential collaborations, please contact us:</p>

                            <div class="footer-2__subscribe d-flex mt-30 mt-xs-25">
                                <input type="text" placeholder="Enter Email"/>
                                <button type="submit" class="rr-btn rr-btn__theme">
                                <Link to="/contact-us">
                                <span class="btn-wrap">
                                    <span class="text-one">Contact Us</span>
                                    <span class="text-two">Contact Us</span>
                                </span>
                                </Link>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="footer-2__bottom-wrapper">
            <div class="container">
                <div class="footer-2__bottom">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="footer-2__copyright text-lg-start text-center">
                            <p class="mb-0"> Copyright © 2024 All Rights Reserved Crafted with precision by<a href="codfty.com" target='blank'> Square Med Tech</a> </p>
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="footer-2__copyright-menu">
                                <ul>
                                    {/* <li><a >Trams & Condition</a></li>
                                    <li><a >Privacy Policy</a></li> */}
                                    <li><Link to="/contact-us">Contact Us</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</footer>
 </>
  )
}

export default Footer