import React from 'react'

const MainForm = () => {
  return (
    <>
         <section class="pricing-appointment section-space">
        <div class="container">
            <div class="row align-items-end mb-60 mb-sm-50 mb-xs-40">
                <div class="col-lg-6">
                    <div class="section__title-wrapper">
                        <h5 class="section__subtitle color-theme-primary mb-15 mb-xs-10 title-animation"><img src="assets/imgs/ask-quesiton/heart.png" alt="icon not found" class="img-fluid"/> Take Appointment</h5>
                        <h2 class="section__title mb-0 title-animation">Radiant Resilience Your Wellness Your Strength</h2>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="pricing-appointment__content-right mt-md-25 mt-sm-25 mt-xs-25">
                        <p class="mb-0">Medical science encompasses a vast array of fields dedicated to understanding and treating ailments, promoting health, and enhanci quality of life. Here's a brief exploration into this multifaceted domain medical student company remain</p>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-4">
                    <div class="pricing-appointment__contact-item__wrap d-flex flex-column">
                        <div class="pricing-appointment__contact-item d-flex align-items-end">
                            <div class="pricing-appointment__contact-item-icon d-flex align-items-center justify-content-center">
                                <i class="fa-solid fa-phone"></i>
                            </div>
                            <div class="pricing-appointment__contact-item-text">
                                <p>Requesting A Call:</p>
                                <h5 class="mb-0"><a href="tel:6295550129">(629) 555-0129</a></h5>
                            </div>
                        </div>

                        <div class="pricing-appointment__contact-item d-flex align-items-end">
                            <div class="pricing-appointment__contact-item-icon d-flex align-items-center justify-content-center">
                                <i class="fa-solid fa-clock"></i>
                            </div>
                            <div class="pricing-appointment__contact-item-text">
                                <p>Sunday - Friday:</p>
                                <h5 class="mb-0">9 am - 8 pm</h5>
                            </div>
                        </div>

                        <div class="pricing-appointment__contact-item d-flex align-items-end">
                            <div class="pricing-appointment__contact-item-icon d-flex align-items-center justify-content-center">
                                <i class="fa-solid fa-location-dot"></i>
                            </div>
                            <div class="pricing-appointment__contact-item-text">
                                <p>6391 Elgin St. Celina, Delaware </p>
                                <h5 class="mb-0"><a href="https://maps.app.goo.gl/1N77c8d8zieLRaot7">Kentucky</a></h5>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-8">
                    <form id="pricing-appointment__form"  method="POST" action="" class="pricing-appointment__form mt-md-60 mt-sm-60 mt-xs-60">
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="pricing-appointment__form-input">
                                    <input name="name" id="name" type="text" placeholder="Your name"/>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="pricing-appointment__form-input">
                                    <input name="email" id="email" type="text" placeholder="Your email"/>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="pricing-appointment__form-input">
                                    <input name="phone" id="phone" type="text" placeholder="Phone"/>
                                </div>
                            </div>
                            <div class="col-sm-6">
                            <div class="pricing-appointment__form-select">
                                <select name="doctor" id="doctor" style={{display:" none"}}>
                                    <option>Choose a Doctor</option>
                                    <option>Dr. Rana</option>
                                    <option>Dr. Sabbir</option>
                                    <option>Dr. Rubel</option>
                                </select>
                                <div class="nice-select open" tabindex="0"><span class="current">Choose a Doctor</span>
                                    <ul class="list">
                                        <li data-value="Choose a Doctor" class="option selected focus">Choose a Doctor</li>
                                        <li data-value="Dr. Rana" class="option">Dr. Rana</li>
                                        <li data-value="Dr. Sabbir" class="option">Dr. Sabbir</li>
                                        <li data-value="Dr. Rubel" class="option">Dr. Rubel</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                            <div class="col-sm-12">
                                <div class="pricing-appointment__form-input">
                                    <textarea name="textarea" id="textarea" placeholder="Messege"></textarea>
                                </div>
                            </div>

                            <div class="col-12">
                                <button type="submit" class="rr-btn rr-btn__primary-color mt-0">
                                    <span class="btn-wrap">
                                        <span class="text-one">Send Now</span>
                                        <span class="text-two">submit</span>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default MainForm