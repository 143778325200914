import React from 'react'
import HeaderMain from './HeaderMain'
import { Link } from 'react-router-dom'
import Footer from './Footer'
import FooterWhite from './FooterWhite'
import BreadcrumbArea from '../ShearOne/BreadcrumbArea'

const Error404 = () => {
    const breadcrumbLinks = [
        { path: '/', label: 'Home' },
        { label: '404 Error', active: true }
      ];
  return (
    <>
    <HeaderMain/>
        <main>
   
    <BreadcrumbArea 
      title="404 Error" 
      backgroundImage="/assets/imgs/breadcrumb/page-header-1.png" 
      links={breadcrumbLinks} 
    />
    
    <section class="error section-space">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="error__media text-lg-end text-center">
                        <img class="img-fluid" src="/assets/imgs/404/404.png" alt="icon not found"/>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="error__content mt-md-55 mt-sm-50 mt-xs-40">
                        <h1 class="title">404</h1>
                        <p class="mb-40 mb-xs-30">Ethics are integral to medical practice, guiding decisions regarding high patient care, confidentiality, consent, and end-of-life care</p>
                        <a href="index.html" class="rr-btn">
                            <span class="btn-wrap">
                                <span class="text-one">Go Back Home</span>
                                <span class="text-two">Go Back Home</span>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>
<section class="footer__cta footer__cta-bottom-up">
    <div class="container">
        <div class="row mb-minus-30">
            <div class="col-md-6">
                <div class="footer__cta-item mb-30 theme-bg-primary d-flex flex-lg-row flex-column align-items-lg-center">
                    <div class="footer__cta-item-icon d-flex align-items-center justify-content-center">
                        <img class="img-fluid" src="/assets/imgs/footer__cta/footer__cta-item-1.png" alt="icon not found"/>
                    </div>

                    <div class="footer__cta-item-text">
                        <h4 class="color-white mb-15 mb-10">Expert Eye Care</h4>
                        <p class="color-white mb-0">Et purus duis sollicitudin dignissim habitant. Egestas nulla quis venenatis cras sed eu massa eu faucibus</p>
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="footer__cta-item mb-30 theme-bg-glow d-flex flex-lg-row flex-column align-items-lg-center">
                    <div class="footer__cta-item-icon d-flex align-items-center justify-content-center">
                        <img class="img-fluid" src="/assets/imgs/footer__cta/footer__cta-item-2.png" alt="icon not found"/>
                    </div>

                    <div class="footer__cta-item-text">
                        <h4 class="color-white mb-15 mb-10">Eye Health Provider</h4>
                        <p class="color-white mb-0">Et purus duis sollicitudin dignissim habitant. Egestas nulla quis venenatis cras sed eu massa eu faucibus</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<FooterWhite/>
    </>
  )
}

export default Error404