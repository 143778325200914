
import React, { useState, useEffect } from 'react';
import axiosInstance from '../api/axiosInstance';

const CategoryManagement = () => {
  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [currentCategoryId, setCurrentCategoryId] = useState(null);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axiosInstance.get('categories');
        setCategories(response.data);
      } catch (err) {
        console.error('Error fetching categories', err);
      }
    };

    fetchCategories();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editMode) {
        await axiosInstance.put(`categories/${currentCategoryId}`, { name: newCategory });
      } else {
        await axiosInstance.post('categories', { name: newCategory });
      }
      const response = await axiosInstance.get('categories');
      setCategories(response.data);
      setNewCategory('');
      setEditMode(false);
      setCurrentCategoryId(null);
    } catch (err) {
      console.error('Error creating/updating category', err);
    }
  };

  const handleEdit = (category) => {
    setNewCategory(category.name);
    setEditMode(true);
    setCurrentCategoryId(category._id);
  };

  const handleDelete = async (id) => {
    try {
      await axiosInstance.delete(`categories/${id}`);
      const response = await axiosInstance.get('categories');
      setCategories(response.data);
    } catch (err) {
      console.error('Error deleting category', err);
    }
  };

  return (
    <div className="container">
      <h2>Category Management</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Category Name</label>
          <input
            type="text"
            value={newCategory}
            onChange={(e) => setNewCategory(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="btn btn-primary">{editMode ? 'Update Category' : 'Add Category'}</button>
      </form>
      <h3>Categories</h3>
      <ul>
        {categories.map((category) => (
          <li key={category._id}>
            {category.name}
            <button onClick={() => handleEdit(category)} className="btn btn-info">Edit</button>
            <button onClick={() => handleDelete(category._id)} className="btn btn-secondary">Delete</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CategoryManagement;
