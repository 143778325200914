
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { getblogelement } from '../../Services/constant';
import { Link } from 'react-router-dom';

const BlogList = () => {
 
  const [data, setData] = useState([]);

  useEffect(() => {
    axios.get(getblogelement())
      .then((response) => {
        const formattedData = response.data.data.map((e) => ({
          grid: 'col-xl-4 col-md-6',
          img: e.image,
          lbbutton: e.title,
          p: <p><span>{e.sub_title}</span></p>,
          h5: e.heading,
          content: truncateText(e.description, 17), // Truncate content to 20 words
          id: e.id, // Add ID to the data
          date: formatDate(e.updated_at), // Format the date

        }));
        setData(formattedData);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "2-digit" };
    return new Intl.DateTimeFormat("en-US", options).format(new Date(dateString));
  };
  
  const truncateText = (htmlContent, wordLimit) => {
    if (!htmlContent) return '';
    // Create a temporary DOM element to parse HTML content
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlContent;
  
    // Extract and truncate text content
    const textContent = tempDiv.textContent || tempDiv.innerText || '';
    const truncatedText = textContent.split(/\s+/).slice(0, wordLimit).join(' ');
  
    // Return the truncated text wrapped in a <p> tag
    return `<p>${truncatedText}...</p>`;
  };
    

  return (
   <>
    <section class="blog-3 section-space">
        <div class="container">
            <div class="row mb-minus-30">
            {data && data.map(res => {
                return <div key={res.id} className={res.grid}>
                    <div class="blog-3__item mb-30">
                        <a  class="blog-3__item-media d-block">
                        <Link to={`/BlogDetails/${res.id}`}>
                            <img class="img-fluid" src={res.img} alt="icon not found"/>
                            </Link>
                        </a>

                        <div class="blog-3__item-content">
                            <a href="blog.html" class="rr-btn mb-20 d-inline-block">
                                <span class="btn-wrap">
                                    <span class="text-one">Admin</span>
                                    <span class="text-two">Admin</span>
                                </span>
                            </a>

                            <ul class="blog-3__item-content-list">
                                <li>{res.date}</li>
                                <li><a >Comments (05)</a></li>
                            </ul>

                            <h4 class="mt-10 mb-15 text-capitalize"><Link to={`/BlogDetails/${res.id}`}>
                            {res?.lbbutton}</Link></h4>

                            <p dangerouslySetInnerHTML={{ __html: res?.content }}></p>

                        <Link class="rr-a-btn mt-30 d-block" to={`/BlogDetails/${res.id}`}>
                            View More <i class="fa-solid fa-circle-plus"></i> </Link>
                        </div>
                    </div>
                </div>
              })}
                <div class="col-xl-4 col-md-6">
                    <div class="blog-3__item mb-30">
                        <a  class="blog-3__item-media d-block">
                            <img class="img-fluid" src="/assets/imgs/blog-3/blog-3__item-1.png" alt="icon not found"/>
                        </a>

                        <div class="blog-3__item-content">
                            <a href="blog.html" class="rr-btn mb-20 d-inline-block">
                                <span class="btn-wrap">
                                    <span class="text-one">Admin</span>
                                    <span class="text-two">Admin</span>
                                </span>
                            </a>

                            <ul class="blog-3__item-content-list">
                                <li>October 19, 2022</li>
                                <li><a >Comments (05)</a></li>
                            </ul>

                            <h4 class="mt-10 mb-15 text-capitalize"><a >Thrive Trail Guiding Your Journey to Wellness</a></h4>

                            <p class="mb-0">Medical science encompasses a vast array of fields dedi to understanding and treating ailments, promoting health enhan</p>

                            <a class="rr-a-btn mt-30 d-block" >View More <i class="fa-solid fa-circle-plus"></i></a>
                        </div>
                    </div>
                </div>

                <div class="col-xl-4 col-md-6">
                    <div class="blog-3__item mb-30">
                        <a  class="blog-3__item-media d-block">
                            <img class="img-fluid" src="/assets/imgs/blog-3/blog-3__item-2.png" alt="icon not found"/>
                        </a>

                        <div class="blog-3__item-content">
                            <a href="blog.html" class="rr-btn mb-20 d-inline-block">
                                <span class="btn-wrap">
                                    <span class="text-one">Admin</span>
                                    <span class="text-two">Admin</span>
                                </span>
                            </a>

                            <ul class="blog-3__item-content-list">
                                <li>October 19, 2022</li>
                                <li><a >Comments (05)</a></li>
                            </ul>

                            <h4 class="mt-10 mb-15 text-capitalize"><a >Essence of Wellness   Health Potential</a></h4>

                            <p class="mb-0">Medical science encompasses a vast array of fields dedi to understanding and treating ailments, promoting health enhan</p>

                            <a class="rr-a-btn mt-30 d-block" >View More <i class="fa-solid fa-circle-plus"></i></a>
                        </div>
                    </div>
                </div>

                <div class="col-xl-4 col-md-6">
                    <div class="blog-3__item mb-30">
                        <a  class="blog-3__item-media d-block">
                            <img class="img-fluid" src="/assets/imgs/blog-3/blog-3__item-3.png" alt="icon not found"/>
                        </a>

                        <div class="blog-3__item-content">
                            <a href="blog.html" class="rr-btn mb-20 d-inline-block">
                                <span class="btn-wrap">
                                    <span class="text-one">Admin</span>
                                    <span class="text-two">Admin</span>
                                </span>
                            </a>

                            <ul class="blog-3__item-content-list">
                                <li>October 19, 2022</li>
                                <li><a >Comments (05)</a></li>
                            </ul>

                            <h4 class="mt-10 mb-15 text-capitalize"><a >Wellness Weaves Crafting a Tapestry of Good Health</a></h4>

                            <p class="mb-0">Medical science encompasses a vast array of fields dedi to understanding and treating ailments, promoting health enhan</p>

                            <a class="rr-a-btn mt-30 d-block" >View More <i class="fa-solid fa-circle-plus"></i></a>
                        </div>
                    </div>
                </div>

                <div class="col-xl-4 col-md-6">
                    <div class="blog-3__item mb-30">
                        <a  class="blog-3__item-media d-block">
                            <img class="img-fluid" src="/assets/imgs/blog/blog-item-1.jpg" alt="icon not found"/>
                        </a>

                        <div class="blog-3__item-content">
                            <a href="blog.html" class="rr-btn mb-20 d-inline-block">
                                <span class="btn-wrap">
                                    <span class="text-one">Admin</span>
                                    <span class="text-two">Admin</span>
                                </span>
                            </a>

                            <ul class="blog-3__item-content-list">
                                <li>October 19, 2022</li>
                                <li><a >Comments (05)</a></li>
                            </ul>

                            <h4 class="mt-10 mb-15 text-capitalize"><a >Optimal Oasis Nurturing Health in Every Aspect</a></h4>

                            <p class="mb-0">Medical science encompasses a vast array of fields dedi to understanding and treating ailments, promoting health enhan</p>

                            <a class="rr-a-btn mt-30 d-block" >View More <i class="fa-solid fa-circle-plus"></i></a>
                        </div>
                    </div>
                </div>

                <div class="col-xl-4 col-md-6">
                    <div class="blog-3__item mb-30">
                        <a  class="blog-3__item-media d-block">
                            <img class="img-fluid" src="/assets/imgs/blog/blog-item-2.jpg" alt="icon not found"/>
                        </a>

                        <div class="blog-3__item-content">
                            <a href="blog.html" class="rr-btn mb-20 d-inline-block">
                                <span class="btn-wrap">
                                    <span class="text-one">Admin</span>
                                    <span class="text-two">Admin</span>
                                </span>
                            </a>

                            <ul class="blog-3__item-content-list">
                                <li>October 19, 2022</li>
                                <li><a >Comments (05)</a></li>
                            </ul>

                            <h4 class="mt-10 mb-15 text-capitalize"><a >Embark on Health Wellness Begins</a></h4>

                            <p class="mb-0">Medical science encompasses a vast array of fields dedi to understanding and treating ailments, promoting health enhan</p>

                            <a class="rr-a-btn mt-30 d-block" >View More <i class="fa-solid fa-circle-plus"></i></a>
                        </div>
                    </div>
                </div>

                <div class="col-xl-4 col-md-6">
                    <div class="blog-3__item mb-30">
                        <a  class="blog-3__item-media d-block">
                            <img class="img-fluid" src="/assets/imgs/blog/blog-item-3.jpg" alt="icon not found"/>
                        </a>

                        <div class="blog-3__item-content">
                            <a href="blog.html" class="rr-btn mb-20 d-inline-block">
                                <span class="btn-wrap">
                                    <span class="text-one">Admin</span>
                                    <span class="text-two">Admin</span>
                                </span>
                            </a>

                            <ul class="blog-3__item-content-list">
                                <li>October 19, 2022</li>
                                <li><a >Comments (05)</a></li>
                            </ul>

                            <h4 class="mt-10 mb-15 text-capitalize"><a >Flourishing Healthier Revive Radiance</a></h4>

                            <p class="mb-0">Medical science encompasses a vast array of fields dedi to understanding and treating ailments, promoting health enhan</p>

                            <a class="rr-a-btn mt-30 d-block" >View More <i class="fa-solid fa-circle-plus"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
   </>
  );
};

export default BlogList;



export const OurBlog = () => {

    const [data, setData] = useState([]);
  
    useEffect(() => {
      axios.get(getblogelement())
        .then((response) => {
          // Sort data by created_at field in descending order
          const sortedData = response.data.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    
          // Take the first three elements
          const latestThreeData = sortedData.slice(0, 3);
    
          // Format data
          const formattedData = latestThreeData.map((e) => ({
            grid: 'col-md-4',
            img: e.image,
            lbbutton: e.title,
            p: <p><span>{e.created_at}</span><span>10 Comments</span></p>,
            h5: e.heading,
            content: e.description,
            id: e.id // Add ID to the data
          }));
    
          setData(formattedData);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }, []);
    
    return <>
      <div className="container ">
        <div className='text-center'>
          <i>From Blog</i>
          <h3>NEWS AND ARTICLES</h3>
        </div>
        <div className="row py-3">
        {data && data.map(res => {
          return <div  key={res.id}  className={res.grid}>
          <Link to={`/BlogDetails/${res.id}`}>
            <div className="blog-box">
              <div className='blog-img'>
                <img src={res.img} alt="" />
                <div className="blog-label">
                  {res.icon}
                  <div className='blog-lb-btn'>
                    {res.lbbutton}
                  </div>
                </div>
              </div>
              <div className='blog-content'>
                {res.p}
                <h5>{res.h5}</h5>
                {/* <p>{res.content}</p> */}
              </div>
            </div>
            </Link>
          </div>
  
        })}
  
      </div>
        {/* <News data={newsdata} /> */}
      </div>
    </>
  }