
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { getClinicalLearningelement } from '../../Services/constant';
import { Link } from 'react-router-dom';

const RegulationsList = () => {
 
  const [data, setData] = useState([]);

  useEffect(() => {
    axios.get(getClinicalLearningelement())
      .then((response) => {
        const filteredData = response.data.data.filter(e => e.type === '1' );

        const formattedData = filteredData.map((e) => ({
          grid: 'col-xl-4 col-md-6',
          img: e.image,
          lbbutton: e.title,
          p: <p><span>{e.sub_title}</span></p>,
          h5: e.heading,
          content: truncateText(e.description, 17), // Truncate content to 20 words
          id: e.id, // Add ID to the data
          date: formatDate(e.updated_at), // Format the date

        }));
        setData(formattedData);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);
  const truncateText = (htmlContent, wordLimit) => {
    if (!htmlContent) return '';
    // Create a temporary DOM element to parse HTML content
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlContent;
  
    // Extract and truncate text content
    const textContent = tempDiv.textContent || tempDiv.innerText || '';
    const truncatedText = textContent.split(/\s+/).slice(0, wordLimit).join(' ');
  
    // Return the truncated text wrapped in a <p> tag
    return `<p>${truncatedText}...</p>`;
  };
    
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "2-digit" };
    return new Intl.DateTimeFormat("en-US", options).format(new Date(dateString));
  };

  return (
   <>
    <section class="blog-3 section-space">
        <div class="container">
            <div class="row mb-minus-30">
            {data && data.map(res => {
                return <div key={res.id} className={res.grid}>
                    <div class="blog-3__item mb-30">
                        <a  class="blog-3__item-media d-block">
                        <Link to={`/regulations-details/${res.id}`}>
                            <img class="img-fluid" src={res.img} alt="icon not found"/>
                            </Link>
                        </a>

                        <div class="blog-3__item-content">
                            <a href="blog.html" class="rr-btn mb-20 d-inline-block">
                                <span class="btn-wrap">
                                    <span class="text-one">Admin</span>
                                    <span class="text-two">Admin</span>
                                </span>
                            </a>

                            <ul class="blog-3__item-content-list">
                                <li>{res.date}</li>
                                <li><a >Comments (05)</a></li>
                            </ul>

                            <h4 class="mt-10 mb-15 text-capitalize"><Link to={`/regulations-details/${res.id}`}>
                            {res?.lbbutton}</Link></h4>

                            <p dangerouslySetInnerHTML={{ __html: res?.content }}></p>

                        <Link class="rr-a-btn mt-30 d-block" to={`/regulations-details/${res.id}`}>
                            View More <i class="fa-solid fa-circle-plus"></i> </Link>
                        </div>
                    </div>
                </div>
              })}
                

            

               

                <div class="col-xl-4 col-md-6">
                    <div class="blog-3__item mb-30">
                        <a  class="blog-3__item-media d-block">
                            <img class="img-fluid" src="/assets/imgs/blog/blog-item-1.jpg" alt="icon not found"/>
                        </a>

                        <div class="blog-3__item-content">
                            <a href="blog.html" class="rr-btn mb-20 d-inline-block">
                                <span class="btn-wrap">
                                    <span class="text-one">Admin</span>
                                    <span class="text-two">Admin</span>
                                </span>
                            </a>

                            <ul class="blog-3__item-content-list">
                                <li>October 19, 2022</li>
                                <li><a >Comments (05)</a></li>
                            </ul>

                            <h4 class="mt-10 mb-15 text-capitalize"><a >Optimal Oasis Nurturing Health in Every Aspect</a></h4>

                            <p class="mb-0">Medical science encompasses a vast array of fields dedi to understanding and treating ailments, promoting health enhan</p>

                            <a class="rr-a-btn mt-30 d-block" >View More <i class="fa-solid fa-circle-plus"></i></a>
                        </div>
                    </div>
                </div>

                <div class="col-xl-4 col-md-6">
                    <div class="blog-3__item mb-30">
                        <a  class="blog-3__item-media d-block">
                            <img class="img-fluid" src="/assets/imgs/blog/blog-item-2.jpg" alt="icon not found"/>
                        </a>

                        <div class="blog-3__item-content">
                            <a href="blog.html" class="rr-btn mb-20 d-inline-block">
                                <span class="btn-wrap">
                                    <span class="text-one">Admin</span>
                                    <span class="text-two">Admin</span>
                                </span>
                            </a>

                            <ul class="blog-3__item-content-list">
                                <li>October 19, 2022</li>
                                <li><a >Comments (05)</a></li>
                            </ul>

                            <h4 class="mt-10 mb-15 text-capitalize"><a >Embark on Health Wellness Begins</a></h4>

                            <p class="mb-0">Medical science encompasses a vast array of fields dedi to understanding and treating ailments, promoting health enhan</p>

                            <a class="rr-a-btn mt-30 d-block" >View More <i class="fa-solid fa-circle-plus"></i></a>
                        </div>
                    </div>
                </div>

                <div class="col-xl-4 col-md-6">
                    <div class="blog-3__item mb-30">
                        <a  class="blog-3__item-media d-block">
                            <img class="img-fluid" src="/assets/imgs/blog/blog-item-3.jpg" alt="icon not found"/>
                        </a>

                        <div class="blog-3__item-content">
                            <a href="blog.html" class="rr-btn mb-20 d-inline-block">
                                <span class="btn-wrap">
                                    <span class="text-one">Admin</span>
                                    <span class="text-two">Admin</span>
                                </span>
                            </a>

                            <ul class="blog-3__item-content-list">
                                <li>October 19, 2022</li>
                                <li><a >Comments (05)</a></li>
                            </ul>

                            <h4 class="mt-10 mb-15 text-capitalize"><a >Flourishing Healthier Revive Radiance</a></h4>

                            <p class="mb-0">Medical science encompasses a vast array of fields dedi to understanding and treating ailments, promoting health enhan</p>

                            <a class="rr-a-btn mt-30 d-block" >View More <i class="fa-solid fa-circle-plus"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
   </>
  );
};

export default RegulationsList;
