import React from 'react';
import { Link } from 'react-router-dom';

const BreadcrumbArea = ({ title, backgroundImage, links }) => {
    const handleShare = async () => {
        const host = window.location.hostname;  // Get the host
        const port = window.location.port;      // Get the port
        const pathname = window.location.pathname; // Get the pathname
        const protocol = window.location.protocol; // Get the protocol
        const url = `${protocol}//${host}${port ? `:${port}` : ''}${pathname}`; // Construct the URL

        const shareData = {
            title: title,
            text: `Check out this post: ${title}`,
            url: url,
        };

        try {
            if (navigator.share) {
                await navigator.share(shareData);
                console.log('Shared successfully');
            } else {
                await navigator.clipboard.writeText(url);
                console.log('URL copied to clipboard');
            }
        } catch (err) {
            console.error('Error sharing:', err);
        }
    };
  return (
    // style={{ backgroundImage: 'url(https://picsum.photos/2000/1000)' }}
    <div className="breadcrumb__area header__background-color breadcrumb__header-up breadcrumb-space overly overflow-hidden">
      <div className="breadcrumb__background" style={{ backgroundImage: `url(${backgroundImage})` }}></div>
      <div className="container">
        <div className="row align-items-center justify-content-between">
          <div className="col-12">
            <div className="breadcrumb__content text-center">
              <h2 className="breadcrumb__title mb-15 mb-sm-10 mb-xs-5 color-white title-animation">{title}</h2>
              <div className="breadcrumb__menu">
                <nav>
                  <ul>
                    {links.map((link, index) => (
                      <li key={index} className={link.active ? 'active' : ''}>
                        <span>
                          {link.path ? <Link to={link.path}>{link.label}</Link> : link.label}
                        </span>
                      </li>
                    ))}
                    {/* <div  onClick={handleShare} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                    <Share /><span>Share</span>
                    </div> */}
                  </ul>
               
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BreadcrumbArea;
