import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './Slider.css'; // Import your custom styles

const slideData = [
    {
      id: 1,
      title: "MEDTECH SOLUTIONS",
      content: "Our Multidisciplinary Professionals with extensive industry experience offer solutions for:",
      list: [
        "QMS and Regulatory Solutions",
        "Medical Device Registration and Submissions",
        "Medical Writing, Technical Documentation, and Updates",
        "Digital Health and Healthcare Consultancy",
        "Clinical Investigation Planning and Project Management",
        "Clinical Research and Non-commercial Trials Consultancy",
        "Data Management and Regulatory Consultancy",
        "Comprehensive Clinical and Regulatory Consultancy Services"
      ],
      imageUrl: "assets/imgs/banner-2/banner-2.png",
      animationClass: "fadeInUp"
    },
    {
      id: 2,
      title: "MEDICAL DEVICE CONSULTING",
      content: "Our experts provide comprehensive assistance in building Medical Device Consulting:",
      list: [
        "QMS",
        "Technical Documentation",
        "Post Market Activities",
        "QA/RA Service",
        "Product Registrations",
        "Digital Health Services",
        "Data Protection",
        "Regulatory Life Cycle"
      ],
      imageUrl: "assets/imgs/banner-3/banner-3.png",
      animationClass: "bounceInLeft"
    },
    {
      id: 3,
      title: "CRO SOLUTIONS",
      content: "Our flexible approach is designed to meet your specific CRO needs through:",
      list: [
        "Clinical Evaluation Planning",
        "Clinical Evaluation Reporting",
        "Literature Review for Clinical Evaluation",
        "Post-Market Clinical Follow-up (PMCF) for Clinical Evaluation",
        "Clinical Investigation Planning",
        "Clinical Investigation Design and Protocol Development",
        "Clinical Data Collection and Management",
        "Clinical Investigation Reporting and Analysis"
      ],
      imageUrl: "assets/imgs/banner-3/banner-2.png",
      animationClass: "slideInRight"
    },
    {
      id: 4,
      title: "HEALTHCARE CONSULTING ",
      content: "Our Healthcare Consulting ensures products are safe, effective, and meet global standards:",
      list: [
        "EU MDR Compliance Consulting",
        "FDA Regulatory Guidance",
        "NMPA Market Entry Strategy",
        "Global Regulatory Submissions Support",
        "Healthcare Survey Design",
        "Patient and Provider Survey Execution",
        "Data Collection and Analysis for Surveys",
        "Regulatory Reporting of Survey Findings"
      ],
      imageUrl: "assets/imgs/banner-3/banner-1.png",
      animationClass: "zoomIn"
    }
  ];
  
  
// const slideData = [
//     {
//         id: 1,
//         title: "SOLUTIONS",
//         content: "Our Multidisciplinary Professionals with extensive industry experience offer solutions for:",
//         list: [
//             "QMS Solutions",
//             "Medical Device Registration Solutions",
//             "Medical Writing & Technical Document Update Solutions",
//             "Digital Health Solutions",
//             "Clinical Investigation Planning & Project Management Solutions",
//             "Regulatory Submissions Solutions",
//             "Non-commercial Clinical Trials Solutions",
//             "Healthcare Consultancy Solutions",
//             "Clinical Research Consultancy Solutions",
//             "Regulatory Consultancy Solutions",
//             "Data Management Consultancy Solutions"
//         ],
//         imageUrl: "assets/imgs/banner-2/banner-2.png",
//         animationClass: "fadeInUp"
//     },
//     {
//         id: 2,
//         title: "MEDICAL DEVICE CONSULTING",
//         content: "Our experts provide assistance in building Medical Device Consulting:",
//         list: [
//             "QMS",
//             "Technical Documentation",
//             "Post Market Activities",
//             "QA/RA Service",
//             "Product Registrations",
//             "Digital Health Services",
//             "Data Protection",
//             "Regulatory Life Cycle"
//         ],
//         imageUrl: "assets/imgs/banner-3/banner-3.png",
//         animationClass: "bounceInLeft"
//     },
//     {
//         id: 3,
//         title: "CRO SOLUTIONS",
//         content: "Our flexible approach is designed to meet your specific CRO needs through:",
//         list: [
//             "Clinical Evaluation",
//             "Clinical Investigation"
//         ],
//         imageUrl: "assets/imgs/banner-3/banner-2.png",
//         animationClass: "slideInRight"
//     },
//     {
//         id: 4,
//         title: "HEALTHCARE CONSULTING SOLUTIONS",
//         content: "Our Healthcare Consulting ensures products are safe, effective, and meet global standards:",
//         list: [
//             "EU MDR/FDA/NMPA Consulting",
//             "Healthcare Survey"
//         ],
//         imageUrl: "assets/imgs/banner-3/banner-1.png",
//         animationClass: "zoomIn"
//     }
// ];

const BannerSlider = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 4000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 8000,
        fade: true // This will apply the fade animation, you can customize animations in your CSS
    };

    return (
        <Slider {...settings}>
            {slideData.map(slide => (
                <div key={slide.id} className={`banner-slide banner-slide${slide.id}`}>
                    <section className="banner-2 banner-2__space theme-bg-color-900 overflow-hidden" style={{ backgroundImage: 'url(/assets/imgs/banner-2/banner-shape.png)' }}>
                        <div className="container">
                            <div className="banner-2__shape"></div>
                            <div className="row align-items-center">
                                <div className="col-lg-7">
                                    <div className={`banner-2__content ${slide.animationClass}`}>
                                        <h3 className="mb-50 mb-xs-40 color-white">{slide.title}</h3>
                                        <p className="mb-50 mb-xs-40 color-white">{slide.content}</p>
                                        <ul className="mb-50 mb-xs-40 color-white">
                                            {slide.list.map((item, index) => (
                                                <li key={index}>{item}</li>
                                            ))}
                                        </ul>
                                        <div className="rr-btn__wrapper d-flex align-items-sm-center align-items-start flex-column flex-sm-row">
                                            <a href="appoinment" className="rr-btn position-relative overflow-hidden">
                                                <div className="panel wow"></div>
                                                <span className="btn-wrap">
                                                    <span className="text-one">Free Consultation</span>
                                                    <span className="text-two">Free Consultation</span>
                                                </span>
                                            </a>
                                            <a href="about-us" className="readmore">Read More
                                                <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M11.122 5.30953L7.18453 9.24703C7.10244 9.32912 6.9911 9.37524 6.875 9.37524C6.7589 9.37524 6.64756 9.32912 6.56547 9.24703C6.48337 9.16494 6.43726 9.05359 6.43726 8.9375C6.43726 8.8214 6.48337 8.71006 6.56547 8.62797L9.75648 5.4375H1.1875C1.07147 5.4375 0.960188 5.39141 0.878141 5.30936C0.796094 5.22731 0.75 5.11603 0.75 5C0.75 4.88397 0.796094 4.77269 0.878141 4.69064C0.960188 4.60859 1.07147 4.5625 1.1875 4.5625H9.75648L6.56547 1.37203C6.48337 1.28994 6.43726 1.1786 6.43726 1.0625C6.43726 0.946402 6.48337 0.835061 6.56547 0.752968C6.64756 0.670875 6.7589 0.624756 6.875 0.624756C6.9911 0.624756 7.10244 0.670875 7.18453 0.752968L11.122 4.69047C11.1627 4.7311 11.195 4.77935 11.217 4.83246C11.239 4.88557 11.2503 4.9425 11.2503 5C11.2503 5.05749 11.239 5.11442 11.217 5.16753C11.195 5.22065 11.1627 5.2689 11.122 5.30953Z" fill="white"/>
                                                </svg>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <div className="banner-2__media">
                                        <div className="banner-2__media-shape upDown"></div>
                                        <img className="img-fluid" src={slide.imageUrl} alt="icon not found"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            ))}
        </Slider>
    );
};

export default BannerSlider;
