import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
const ResourceData = [
    {
      id: 1,
      date: "October 19, 2022",
      author: "Mesbah",
      title: "Smile Brighter Smile Healthier Your Dental Destination",
      description: "Explore the dynamic commerce through our insightful blogs. Learn Explore the dynamic",
      imageUrl: "assets/imgs/blog-2/blog-3.jpg",
      link: "blog-details",
    },
    {
      id: 2,
      date: "October 19, 2022",
      author: "Mesbah",
      title: "Bringing Confidence to Your Smile Dental Excellence",
      description: "Explore the dynamic commerce through our insightful blogs. Learn Explore the dynamic",
      imageUrl: "assets/imgs/blog-2/blog-1.jpg",
      link: "blog-details",
    },
    {
      id: 3,
      date: "October 19, 2022",
      author: "Mesbah",
      title: "Painting a Brighter Health Landscape",
      description: "Explore the dynamic commerce through our insightful blogs. Learn Explore the dynamic",
      imageUrl: "assets/imgs/blog-2/blog-2.jpg",
      link: "blog-details",
    },
    // Add more blog items as needed
  ];
  
const ResourceSection = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };
  return (
  <>
      <section class="blog-2 blog-2__space-tp ">
        <div class="container">
            <div class="row mb-60 mb-xs-50 align-items-lg-end align-items-center">
                <div class="col-lg-6">
                    <div class="section__title-wrapper blog-2__content">
                        <h5 class="section__subtitle color-theme-primary mb-15 mb-xs-10 title-animation"><img src="assets/imgs/ask-quesiton/heart.png" alt="icon not found" class="img-fluid"/> Latest Resource</h5>
                        <h2 class="section__title mb-0 title-animation">Thrive Tribe United For A Healthier Future</h2>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="blog-2__slider__arrow d-flex justify-content-lg-end justify-content-start">
                        <button class="blog-2__slider__arrow-prev d-flex align-items-center justify-content-center">
                            <i class="fa-solid fa-arrow-left"></i>
                        </button>

                        <button class="blog-2__slider__arrow-next d-flex align-items-center justify-content-center">
                            <i class="fa-solid fa-arrow-right"></i>
                        </button>
                    </div>
                </div>
            </div>

            <div className="row">
            <div className="col-12">
                <div className="blog-2__slider">
                <Slider {...settings}>
                    {ResourceData.map((blog) => (
                    <div key={blog.id} className="swiper-slide">
                        <div className="blog-2__item">
                        <a
                            href={blog.link}
                            className="blog-2__item-media d-block position-relative overflow-hidden"
                        >
                            <div className="panel wow"></div>
                            <img
                            className="img-fluid"
                            src={blog.imageUrl}
                            alt="image not found"
                            />
                        </a>

                        <div className="blog-2__item-content">
                            <ul className="blog-2__item-meta-list mb-35 mb-xs-30">
                            <li>
                                <i className="fa-solid fa-calendar-days"></i>{" "}
                                <span>{blog.date}</span>
                            </li>
                            <li>
                                <a href={blog.link}>
                                <i className="fa-regular fa-user"></i> <span>{blog.author}</span>
                                </a>
                            </li>
                            </ul>

                            <h4 className="mb-15 mb-xs-10">
                            <a href={blog.link}>{blog.title}</a>
                            </h4>
                            <p className="mb-40 mb-xs-30">{blog.description}</p>

                            <a className="rr-a-btn" href={blog.link}>
                            View More <i className="fa-solid fa-circle-plus"></i>
                            </a>
                        </div>
                        </div>
                    </div>
                    ))}
                </Slider>
                </div>
            </div>
            </div>
        </div>
    </section>
  </>
  )
}

export default ResourceSection