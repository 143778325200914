import React from 'react'
import FooterWhite from '../../../Compoents/FooterWhite'
import BreadcrumbArea from '../../../ShearOne/BreadcrumbArea'
import AboutSection from '../../Home/HomeInner/AboutSection'
import TestimonialSlider from '../../Home/HomeInner/FAQSlider'
import Appoinment from '../../Home/HomeInner/Appoinment'
import HeaderMain from '../../../Compoents/HeaderMain'

export const SqureMedTech = () => {
    const breadcrumbLinks = [
        { path: '/', label: 'Home' },
        { label: 'Square Med Tech', active: true }
      ];
  return (
  <>
    <HeaderMain/>
    <BreadcrumbArea 
     title="Square Med Tech" 
     backgroundImage="/assets/imgs/breadcrumb/page-header-1.png" 
     links={breadcrumbLinks} 
   />

   <AboutSection/>

   <OurTeam/>
   <SolutionProvide/>
   <ProductService/>
   <ClientsTestinomial/>
   <Appoinment/>
<FocusPoint/>
    <FooterWhite/>
  </>
  )
}


export const OurTeam = () => {
  return (
  <>
     <section class="wellness-expertise section-space__bottom">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="wellness-expertise__content">
                    <h5 class="section__subtitle color-theme-primary mb-15 mb-xs-10 title-animation"><img src="assets/imgs/ask-quesiton/heart.png" alt="icon not found" class="img-fluid"/> Our Team </h5>
                        <h3 class="section__title mb-15 mb-xs-10  title-animation">SQUARE MEDTECH team is a mixture of trained healthcare professionals with following backgrounds specialties for MedTech development:</h3>
                  
                        <ul class="wellness-expertise__content__list">
                            <li><i class="fa-solid fa-check"></i> Cardiovascular Medicine (Cath-Lab specialty) </li>
                            <li><i class="fa-solid fa-check"></i>Ultra-sonography </li>
                            <li><i class="fa-solid fa-check"></i>Dentistry</li>
                            <li><i class="fa-solid fa-check"></i>Orthopedic Surgery</li>
                            <li><i class="fa-solid fa-check"></i>General Practitioners</li>
                            <li><i class="fa-solid fa-check"></i>Engineering professionals </li>
                        </ul>
                        <br/>
                        <p>Our team of experienced professionals brings together a wealth of knowledge and expertise in clinical research, regulatory affairs, data management, and patient care. We are committed to continuous learning and improvement, staying abreast of the latest advancements in medical research and technology.</p>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="wellness-expertise__media">
                        <img src="./assets/imgs/wellness-expertise/wellness-expertise.png" class="img-fluid" alt="image not found"/>

                        <div class="wellness-expertise__media__box">
                            <div class="wellness-expertise__media__box-icon">
                                <img class="img-fluid" src="assets/imgs/wellness-expertise/note.svg" alt="icon not found"/>
                            </div>
                            <div class="wellness-expertise__media__box-text">
                                <h3><span class="odometer" data-count="380">0</span>+</h3>
                                <h6>Success Case</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  </>
  )
}


export const SolutionProvide = () => {
  return (
<>
<section class="service-3 section-space__bottom">
        <div class="container">
            <div class="row mb-minus-50">
                
                <div class="col-xl-4 col-lg-4 col-sm-6">
                    <div class="service-3__item mb-50">
                        <div class="service-3__item-header d-flex mb-40 mb-xs-35">
                            <div class="service-3__item-header-icon">
                                <img class="img-fluid" src="assets/imgs/service-3/service-icon-2.png" alt="icon not found"/>
                            </div>
                            <h2 class="mb-0">01</h2>
                        </div>

                        <h4 class="mb-0">Medical Device Manufacturers</h4>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-sm-6">
                    <div class="service-3__item mb-50">
                        <div class="service-3__item-header d-flex mb-40 mb-xs-35">
                            <div class="service-3__item-header-icon">
                                <img class="img-fluid" src="assets/imgs/service-3/service-icon-3.png" alt="icon not found"/>
                            </div>
                            <h2 class="mb-0">02</h2>
                        </div>

                        <h4 class="mb-0">Pharmaceutical & Biotech Industry </h4>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-sm-6">
                    <div class="service-3__item mb-50">
                        <div class="service-3__item-header d-flex mb-40 mb-xs-35">
                            <div class="service-3__item-header-icon">
                                <img class="img-fluid" src="assets/imgs/service-3/service-icon-4.png" alt="icon not found"/>
                            </div>
                            <h2 class="mb-0">03</h2>
                        </div>

                        <h4 class="mb-0">Healthcare Providers</h4>
                    </div>
                </div>
            </div>
        </div>
    </section>
</>
  )
}

export const ProductService = () => {
  return (
    <>
            <section class="service-4 section-space__bottom-100">
        <div class="container">
        <div class="col-lg-12">
                    <div class="">
                    <h5 class="section__subtitle color-theme-primary mb-15 mb-xs-10 title-animation"><img src="assets/imgs/ask-quesiton/heart.png" alt="icon not found" class="img-fluid"/> Our Products & Service   </h5>
                      
                    </div>
                </div>
            <div class="row mb-minus-30" >
                <div class="col-xl-4 col-lg-4 col-md-6">
                    <div class="service-4__item mb-30">
                        <div class="service-4__item-icon mb-40 mb-xs-20">
                            <img src="assets/imgs/service-4/services__item-1.png" alt="icon not found"/>
                        </div>
                        <div class="service-4__item-text">
                            <h4 class="mb-15 mb-xs-10 title-animation">	Medical Device Consulting: </h4>
                            <p>SQUARE MEDTECH Medical Device Consulting offers: </p>
                            <ul>
                                <li>	Essential services for the successful development, approval, and market performance of medical devices. </li>
                                <li>	Key areas include implementing a Quality Management System (QMS), preparing comprehensive Technical Documentation, conducting Post Market Activities, and providing Quality Assurance and Regulatory Affairs Services. </li>
                                <li>	These services ensure regulatory compliance, facilitate product registrations, protect data, and manage the Regulatory Life Cycle to maintain device safety and effectiveness throughout its use.</li>
                               
                                </ul>
                            <a class="rr-a-btn mb-8" >Read More <i class="fa-solid fa-circle-plus"></i></a>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-6">
                    <div class="service-4__item mb-30">
                        <div class="service-4__item-icon mb-40 mb-xs-20">
                            <img src="assets/imgs/service-4/services__item-2.png" alt="icon not found"/>
                        </div>
                        <div class="service-4__item-text">
                            <h4 class="mb-15 mb-xs-10 title-animation"><a >	CRO :</a></h4>
                            <p>SQUARE MEDTECH provides expert support for: </p>
                            <ul>
                                <li>	Clinical investigations and trials of medical devices and pharmaceuticals through specialized services like Clinical Evaluation, Clinical Investigation </li>
                                <li>	Post-Market Clinical Follow-up (PMCF) solutions.</li>
                                <br/>
                                <br/>
                                <br/>
                                <br/>   <br/>
                                <br/>
                                <br/>
                                <br/>
                                </ul>
                            <a class="rr-a-btn" >Read More <i class="fa-solid fa-circle-plus"></i></a>
                         
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-6">
                    <div class="service-4__item mb-30">
                        <div class="service-4__item-icon mb-40 mb-xs-20">
                            <img src="assets/imgs/service-4/services__item-3.png" alt="icon not found"/>
                        </div>
                        <div class="service-4__item-text">
                            <h4 class="mb-15 mb-xs-10 title-animation">		Healthcare Consulting :</h4>
                            <p>SQUARE MEDTECH provides expert support for: </p>
                            <ul>
  <li>	SQUARE MEDTECH offers consulting services to healthcare companies, navigating regulatory landscapes like EU MDR, FDA, and NMPA in China.</li>
  <li>	Conducting thorough healthcare surveys for informed decision-making and strategic product development, enhancing global healthcare outcomes.</li>
  <br/>   <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
</ul>
                            <a class="rr-a-btn" >Read More <i class="fa-solid fa-circle-plus"></i></a>
                         
                        </div>
                    </div>
                </div>
                <h3>Commitment to Quality</h3>
                <p>Quality is at the heart of everything we do. We adhere to the highest standards of Good Clinical Practice (GCP) and regulatory guidelines to ensure the validity and reliability of our research.</p>
            </div>
            
        </div>
    </section>
    </>
  )
}

export const ClientsTestinomial = () => {
  return (
    <>
            <section class="client-testimonial section-space overflow-hidden">
        <div class="container">
            <div class="client-testimonial__shape"><img class="img-fluid" src="assets/imgs/testimonial/client-testimonial__helth.png" alt="icon not found"/></div>
            <div class="row align-items-end mb-60 mb-sm-50 mb-xs-40">
                <div class="col-lg-7">
                    <div class="section__title-wrapper client-testimonial__content">
                        <h5 class="section__subtitle color-theme-primary mb-15 mb-xs-10 title-animation"><img src="assets/imgs/ask-quesiton/heart.png" alt="icon not found" class="img-fluid"/> Clients Testimonial</h5>
                        <h2 class="section__title mb-0 title-animation">Nurture Nature Blossoming in Health and Happiness</h2>
                    </div>
                </div>

                <div class="col-lg-5">
                    <div class="client-testimonial__content-right mt-md-25 mt-sm-25 mt-xs-25">
                        <p class="mb-0">Medical science encompasses a vastf fields dedicated to understanding and treating ailments, promotin health, and enhanci quality of life. Here's a brief exploration into this multifaceted domain medical student</p>
                    </div>
                </div>
            </div>

            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="client-testimonial__media">
                        <img class="img-fluid" src="assets/imgs/client-testimonial/client-testimonial.png" alt="icon not found"/>
                    </div>
                </div>

                <div class="col-lg-6">      
                <TestimonialSlider/>
                   
                </div>
            </div>
        </div>
    </section>
    </>
  )
}




export const FocusPoint = () => {
  return (
    <>
        <section class="footer__cta footer__cta-bottom-up">
    <div class="container">
        <div class="row mb-minus-30">
            <div class="col-md-6">
                <div class="footer__cta-item mb-30 theme-bg-primary d-flex flex-lg-row flex-column align-items-lg-center">
                    <div class="footer__cta-item-icon d-flex align-items-center justify-content-center">
                        <img class="img-fluid" src="assets/imgs/footer__cta/footer__cta-item-1.png" alt="icon not found"/>
                    </div>

                    <div class="footer__cta-item-text">
                        <h4 class="color-white mb-15 mb-10">We Believe:</h4>
                        <ul class="color-white mb-0">
                            <li>Be at the forefront of medical innovation</li>
                            <li>Fostering scientific advancements</li>
                            <li>Global health and well-being</li>
                        </ul>

                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="footer__cta-item mb-30 theme-bg-glow d-flex flex-lg-row flex-column align-items-lg-center">
                    <div class="footer__cta-item-icon d-flex align-items-center justify-content-center">
                        <img class="img-fluid" src="assets/imgs/footer__cta/footer__cta-item-2.png" alt="icon not found"/>
                    </div>

                    <div class="footer__cta-item-text">
                        <h4 class="color-white mb-15 mb-10">We Focus:</h4>
                        <ul class="color-white mb-0">
                            <li>Excellence in Research</li>
                            <li>Collaborative Partnerships</li>
                            <li>Innovative Solutions</li>
                        </ul>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
    </>
  )
}
