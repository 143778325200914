import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './App.css'; // Assuming external styles are in App.css

export const LoginNew = () => {
  const navigate = useNavigate();
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');

  // Email validation regex
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  // Password validation (min 8 characters)
  const validatePassword = (password) => {
    return password.length >= 8;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get('email');
    const password = data.get('password');

    let isValid = true;

    // Validate email
    if (!validateEmail(email)) {
      setEmailError('Invalid email address');
      isValid = false;
    } else {
      setEmailError('');
    }

    // Validate password
    if (!validatePassword(password)) {
      setPasswordError('Password must be at least 8 characters long');
      isValid = false;
    } else {
      setPasswordError('');
    }

    // If valid, proceed to login
    if (isValid) {
      console.log({ email, password });

      // Hardcoded check for credentials
      if (email === 'test@gmail.com' && password === '87654321') {
        // Navigate to dashboard on successful login
        navigate('/dashboard');
      } else {
        alert('Invalid email or password');
      }
    }
  };

  return (
    <>
      <section className="background-radial-gradient overflow-hidden">
        <div className="container px-4 py-5 px-md-5 text-center text-lg-start my-5">
          <div className="row gx-lg-5 align-items-center mb-5">
            <div className="col-lg-6 mb-5 mb-lg-0" style={{ zIndex: 10 }}>
              <h1 className="my-5 display-5 fw-bold ls-tight" style={{ color: 'hsl(218, 81%, 95%)' }}>
                Welcome Back! <br />
                <span style={{ color: 'hsl(218, 81%, 75%)' }}>Login to continue</span>
              </h1>
              <p className="mb-4 opacity-70" style={{ color: 'hsl(218, 81%, 85%)' }}>
                Enter your email and password to access your account.
              </p>
            </div>

            <div className="col-lg-6 mb-5 mb-lg-0 position-relative">
              <div id="radius-shape-1" className="position-absolute rounded-circle shadow-5-strong"></div>
              <div id="radius-shape-2" className="position-absolute shadow-5-strong"></div>

              <div className="card bg-glass">
                <div className="card-body px-4 py-5 px-md-5">
                  <form onSubmit={handleSubmit}>
                    {/* Email input */}
                    <div className="form-outline mb-4">
                      <input type="email" name="email" id="loginEmail" className="form-control" />
                      <label className="form-label" htmlFor="loginEmail">Email address</label>
                      {emailError && <span className="text-danger">{emailError}</span>}
                    </div>

                    {/* Password input */}
                    <div className="form-outline mb-4">
                      <input type="password" name="password" id="loginPassword" className="form-control" />
                      <label className="form-label" htmlFor="loginPassword">Password</label>
                      {passwordError && <span className="text-danger">{passwordError}</span>}
                    </div>

                    {/* Remember me */}
                    <div className="form-check d-flex justify-content-center mb-4">
                      <input className="form-check-input me-2" type="checkbox" id="rememberMe" />
                      <label className="form-check-label" htmlFor="rememberMe">
                        Remember me
                      </label>
                    </div>

                    {/* Submit button */}
                    <button type="submit" className="rr-btn rr-btn__primary-color mt-0">
                      Log in
                    </button>

                    {/* Social login buttons */}
                    <div className="text-center">
                      <p>or log in with:</p>
                      <button type="button" className="btn btn-link btn-floating mx-1">
                        <i className="fab fa-facebook-f"></i>
                      </button>
                      <button type="button" className="btn btn-link btn-floating mx-1">
                        <i className="fab fa-google"></i>
                      </button>
                      <button type="button" className="btn btn-link btn-floating mx-1">
                        <i className="fab fa-twitter"></i>
                      </button>
                      <button type="button" className="btn btn-link btn-floating mx-1">
                        <i className="fab fa-github"></i>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
