import React, { useState } from 'react'
import { Link } from 'react-router-dom'

const HeaderMain = () => {
    const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false);
    const [openDropdown, setOpenDropdown] = useState(null);
    const [openSubDropdown, setOpenSubDropdown] = useState({});
  
    const handleToggle = (index) => {
      setOpenDropdown(openDropdown === index ? null : index);
    };
  
    const handleSubToggle = (parentIndex, index) => {
      setOpenSubDropdown({
        ...openSubDropdown,
        [parentIndex]: openSubDropdown[parentIndex] === index ? null : index
      });
    };
    const handleOffcanvasOpen = () => {
      setIsOffcanvasOpen(true);
    };
  
    const handleOffcanvasClose = () => {
      setIsOffcanvasOpen(false);
    };
  return (
    <>

{isOffcanvasOpen && (
        <div className="fix" data-bs-interval="1000">
          <div className="offcanvas__area info-close info-open">
            <div className="offcanvas__wrapper">
              <div className="offcanvas__content">
                <div className="offcanvas__top d-flex justify-content-between align-items-center">
                  <div className="offcanvas__logo">
                    <Link to="/">
                      <img src="/assets/imgs/logo/Logo_white.png" style={{width:'220px'}} alt="logo not found" />
                      </Link>
                  </div>
                  <div className="offcanvas__close">
                    <button className="offcanvas-close-icon animation--flip" onClick={handleOffcanvasClose}>
                      <span className="offcanvas-m-lines">
                        <span className="offcanvas-m-line line--1"></span>
                        <span className="offcanvas-m-line line--2"></span>
                        <span className="offcanvas-m-line line--3"></span>
                      </span>
                    </button>
                  </div>
                </div>
                <div className="mobile-menu fix mean-container">
                <div className="mean-bar">
                    <a href="#nav" className="meanmenu-reveal" style={{ right: "0px", left: "auto", display: "inline" }}>
                    <span><span><span></span></span></span>
                    </a>
                    <nav className="mean-nav">
                    <ul>
                        <li className="has-mega-menu active">
                        <Link to="/">Home</Link>
                        </li>
                        <li className="has-dropdown">
                        <Link to="javascript:void(0)" onClick={() => handleToggle(0)}>About us</Link>
                        <a className="mean-expand" href="#" style={{ fontSize: "18px" }} onClick={() => handleToggle(0)}>
                            <i className={`fa-regular fa-angle-right ${openDropdown === 0 ? 'open' : ''}`}></i>
                        </a>
                        <ul className="submenu" style={{ display: openDropdown === 0 ? 'block' : 'none' }}>
                        <li>  <Link to="/square-medtech">SQUARE MEDTECH</Link></li>
                                          <li><Link to="/therapeutic-specialties">Therapeutic specialties</Link></li>
                                            <li>  <Link to="/how-we-work">How We Work </Link></li>
                                            <li>  <Link to="/faq">FAQ</Link></li>
                        </ul>
                        </li>
                        <li className="has-dropdown">
                        <Link to=" " onClick={() => handleToggle(1)}>Solutions</Link>
                        <a className="mean-expand" href="#" style={{ fontSize: "18px" }} onClick={() => handleToggle(1)}>
                            <i className={`fa-regular fa-angle-right ${openDropdown === 1 ? 'open' : ''}`}></i>
                        </a>
                        <ul className="submenu" style={{ display: openDropdown === 1 ? 'block' : 'none' }}>
                            <li><Link to="/service">Medical Device Manufacturers</Link></li>
                            <li><Link to="/service-details">Pharmaceutical & Biotech industry</Link></li>
                            <li><Link to="/doctor">Healthcare Providers</Link></li>
                        </ul>
                        </li>
                        <li className="has-dropdown">
                        <Link to=" " onClick={() => handleToggle(2)}>Products & Service</Link>
                        <a className="mean-expand" href="#" style={{ fontSize: "18px" }} onClick={() => handleToggle(2)}>
                            <i className={`fa-regular fa-angle-right ${openDropdown === 2 ? 'open' : ''}`}></i>
                        </a>
                        <ul className="submenu" style={{ display: openDropdown === 2 ? 'block' : 'none' }}>
                            <li>
                            <Link to="" onClick={() => handleSubToggle(2, 0)}>Medical Device consulting</Link>
                            <a className="mean-expand" href="#" style={{ fontSize: "18px" }} onClick={() => handleSubToggle(2, 0)}>
                                <i className={`fa-regular fa-angle-right ${openSubDropdown[2] === 0 ? 'open' : ''}`}></i>
                            </a>
                            <ul className="submenu" style={{ display: openSubDropdown[2] === 0 ? 'block' : 'none' }}>
                                <li><Link to="/doctor-details">Quality Management System</Link></li>
                                <li><Link to="/doctor-details">Technical Documentation</Link></li>
                                <li><Link to="/doctor-details">Post Market Activities</Link></li>
                                <li><Link to="/doctor-details">Quality Assurance /Regulatory Affairs Service</Link></li>
                                <li><Link to="/doctor-details">Product Registrations</Link></li>
                                <li><Link to="/doctor-details">Data protection</Link></li>
                                <li><Link to="/doctor-details">Regulatory Life Cycle</Link></li>
                            </ul>
                            </li>
                            <li>
                            <Link to="" onClick={() => handleSubToggle(2, 1)}>CRO</Link>
                            <a className="mean-expand" href="#" style={{ fontSize: "18px" }} onClick={() => handleSubToggle(2, 1)}>
                                <i className={`fa-regular fa-angle-right ${openSubDropdown[2] === 1 ? 'open' : ''}`}></i>
                            </a>
                            <ul className="submenu" style={{ display: openSubDropdown[2] === 1 ? 'block' : 'none' }}>
                                <li><Link to="/doctor-details">Clinical Evaluation</Link></li>
                                <li><Link to="/doctor-details">Clinical Investigation</Link></li>
                                <li><Link to="/doctor-details">PMCF Solution</Link></li>
                            </ul>
                            </li>
                            <li>
                            <Link to="" onClick={() => handleSubToggle(2, 2)}>Healthcare Consulting</Link>
                            <a className="mean-expand" href="#" style={{ fontSize: "18px" }} onClick={() => handleSubToggle(2, 2)}>
                                <i className={`fa-regular fa-angle-right ${openSubDropdown[2] === 2 ? 'open' : ''}`}></i>
                            </a>
                            <ul className="submenu" style={{ display: openSubDropdown[2] === 2 ? 'block' : 'none' }}>
                                <li><Link to="/doctor-details">EU MDR/FDA/ NMPA Consulting</Link></li>
                                <li><Link to="/doctor-details">Healthcare Survey</Link></li>
                            </ul>
                            </li>
                        </ul>
                        </li>
                        <li className="has-dropdown">
                        <Link to=""  onClick={() => handleToggle(3)}>Resources</Link>
                        <a className="mean-expand" href="#" style={{ fontSize: "18px" }} onClick={() => handleToggle(3)}>
                            <i className={`fa-regular fa-angle-right ${openDropdown === 3 ? 'open' : ''}`}></i>
                        </a>
                        <ul className="submenu" style={{ display: openDropdown === 3 ? 'block' : 'none' }}>
                            <li><Link to="/regulations">Regulations</Link></li>
                            <li><Link to="/acronyms-abbreviations">Acronyms & Abbreviations</Link></li>
                            <li><Link to="/training">Training</Link></li>
                            <li><Link to="/portfolio-details">Videos</Link></li>
                        </ul>
                        </li>
                        <li>
                        <Link to="/blog">Blogs</Link>
                        </li>
                        <li><Link to="/contact-us#">Contact</Link></li>
                    </ul>
                    </nav>
                </div>
                </div>
                <div className="offcanvas__social">
                  <h4 className="offcanvas__title mb-20">Subscribe & Follow</h4>
                  <p className="mb-30">Medical practices evolved over millennia, from ancient civilizations like Egypt and Mesopotamia to the groundbreaking</p>
                  <ul className="header-top-socail-menu d-flex">
                    <li><a href="https://www.facebook.com/"><i className="fab fa-facebook-f"></i></a></li>
                    <li><a href="https://twitter.com/"><i className="fab fa-twitter"></i></a></li>
                    <li><a href="https://www.pinterest.com/"><i className="fab fa-pinterest-p"></i></a></li>
                    <li><a href="https://vimeo.com/"><i className="fab fa-vimeo-v"></i></a></li>
                  </ul>
                </div>
                <div className="offcanvas__btn d-sm-none">
                  <div className="header__btn-wrap">
                    <a href="https://themeforest.net/user/rrdevs/portfolio" className="rr-btn rr-btn__theme rr-btn__theme-white mt-40 mt-sm-35 mt-xs-30">
                      <span className="btn-wrap">
                        <span className="text-one">Purchase Now</span>
                        <span className="text-two">Purchase Now</span>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="offcanvas__overlay overlay-open" onClick={handleOffcanvasClose}></div>
        </div>
      )}
<header>
    <div class="header-4-top theme-bg-primary">
        <div class="container">
            <div class="row">
                <div class="col-8 d-none d-lg-block">
                    <div class="header-4-top__menu d-flex">
                        <a href="https://maps.app.goo.gl/mMXm8BncfQXDwt6XA"><i class="fa-solid fa-location-dot"></i>  Shenzhen, Guangdong, China Zip Code: 518000</a>
                        <a href="tel:+8619811976744"><i class="fa-solid fa-phone"></i>+86 19811976744</a>
                        <a href="mailto:info@squaremedtech.com"><i class="fa-solid fa-envelope"></i> info@squaremedtech.com</a>
                    </div>
                </div>
                <div class="col-lg-4 col-12">
                    <div class="header-4-top__menu-social d-flex justify-content-lg-end justify-content-center">
                        <a href="https://www.facebook.com/"><i class="fab fa-facebook-f"></i></a>
                        <a href="https://www.instagram.com/"><i class="fa-brands fa-instagram"></i></a>
                        <a href="https://twitter.com/">
                            <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.0596 6.77295L15.8879 -0.00195312H14.5068L9.44607 5.8806L5.40411 -0.00195312H0.742188L6.85442 8.89352L0.742188 15.998H2.12338L7.4676 9.78587L11.7362 15.998H16.3981L10.0593 6.77295H10.0596ZM8.16787 8.97189L7.54857 8.0861L2.62104 1.03779H4.74248L8.71905 6.726L9.33834 7.61179L14.5074 15.0056H12.386L8.16787 8.97223V8.97189Z" fill="white"/>
                            </svg>
                        </a>
                        <a href="https://www.linkedin.com/"><i class="fa-brands fa-linkedin"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="header-sticky" class="header__area header-4__background header-4">
        <div class="container">
            <div class="mega__menu-wrapper p-relative">
                <div class="header__main">
                    <div class="header__logo">
                      <Link to="/" >
                            <div class="logo">  
                             <img src="/assets/imgs/logo/logo-tagline.png" alt="logo not found" style={{maxWidth:"200px",background:"white"}}/>
                            </div>
                            </Link>
                    </div>

                    <div class="mean__menu-wrapper d-none d-lg-block">
                        <div class="main-menu main-menu-3">
                        <nav id="mobile-menu">
                                <ul>
                                    <li class=" has-mega-menu active">
                                    <Link to="/">Home</Link>
                                    </li>
                                    <li class="has-dropdown ">  
                                    <Link to="javascript:void(0)">About us</Link>
                                        <ul class="submenu">
                                            <li>  <Link to="/square-medtech">SQUARE MEDTECH</Link></li>
                                          <li><Link to="/therapeutic-specialties">Therapeutic specialties</Link></li>
                                            <li>  <Link to="/how-we-work">How We Work </Link></li>
                                            <li>  <Link to="/faq">FAQ</Link></li>
                                        </ul>
                                    </li>
                                    <li class="has-dropdown">
                                    <Link to="service">Solutions</Link>
                                        <ul class="submenu">
                                            <li>  <Link to="service">Medical Device Manufacturers </Link></li>
                                            <li>  <Link to="service-details">Pharmaceutical & Biotech industry </Link></li>
                                            <li>  <Link to="doctor">Healthcare Providers</Link></li>
                                        </ul>
                                    </li>
                                    <li class="has-dropdown">
                                    <Link to="doctor">Products & Service </Link>
                                        <ul class="submenu">
                                        <li>  <Link to="doctor-details">	Medical Device consulting</Link>
                                        <ul class="submenu">
                                           <li>  <Link to="doctor-details">Quality Management System</Link></li>
                                            <li>  <Link to="doctor-details">Technical Documentation</Link></li>
                                            <li>  <Link to="doctor-details">Post Market Activities</Link></li>
                                            <li>  <Link to="doctor-details">Quality Assurance /Regulatory Affairs Service</Link></li>
                                            <li>  <Link to="doctor-details">Product Registrations</Link></li>
                                            <li>  <Link to="doctor-details">Digital Health Services </Link></li>
                                            <li>  <Link to="doctor-details">Data protection</Link></li>
                                            <li>  <Link to="doctor-details">Regulatory Life Cycle</Link></li>
                                                </ul> 
                                            </li>
                                            <li>  <Link to="doctor-details">CRO</Link>
                                        <ul class="submenu">
                                           <li>  <Link to="doctor-details">Clinical Evaluation</Link></li>
                                            <li>  <Link to="doctor-details">Clinical Investigation </Link></li>
                                            <li>  <Link to="doctor-details">PMCF Solution </Link></li>
                                                </ul> 
                                            </li>
                                            <li>  <Link to="doctor-details">	Healthcare Consulting</Link>
                                        <ul class="submenu">
                                           <li>  <Link to="doctor-details">EU MDR/FDA/ NMPA Consulting  </Link></li>
                                            <li>  <Link to="doctor-details">	Healthcare Survey</Link></li>
                                                </ul> 
                                                
                                            </li>
                                        </ul>
                                        
                                    </li>
                                    <li class="has-dropdown">
                                    <Link to="portfolio">Resources</Link>
                                        <ul class="submenu">
                                            <li>  <Link to="/regulations">Regulations</Link></li>
                                            <li>  <Link to="/acronyms-abbreviations">Acronyms & Abbreviations</Link></li>
                                            <li>  <Link to="/training">Training</Link></li>
                                            <li>  <Link to="portfolio-details"> Videos</Link></li>
                                        </ul>
                                    </li>
                                    <li class="has-">
                                    <Link to="/blog">Blogs</Link>
                                        {/* <ul class="submenu">
                                            <li><a to="blog">Blog</Link></li>
                                            <li><a to="blog-details">Blog Details</Link></li>
                                        </ul> */}
                                    </li>
                                    <li>  <Link to="/contact-us#">contact</Link></li>
                                </ul>
                            </nav>
                        </div>
                    </div>

                    <div class="header__right">
                        <div class="header__action d-flex align-items-center">
                            <div class="header__btn-wrap d-none d-sm-inline-flex">
                                <div class="rr-header-icon-search">
                                    <button class="search-open-btn">
                                        <i class="fa-solid fa-magnifying-glass"></i>
                                    </button>
                                </div>
                            </div>

                            <div className="header__hamburger">
                                <div className="sidebar__toggle">
                                <a className="bar-icon" href="javascript:void(0)" onClick={handleOffcanvasOpen}>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>
    </>
  )
}

export default HeaderMain