import React, { useState, useEffect } from 'react';
import { Container, Grid, Typography, Button } from '@mui/material';
import axiosInstance from '../api/axiosInstance';
import { useNavigate } from 'react-router-dom';

const BlogReport = () => {
  const [blogs, setBlogs] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axiosInstance.get('/blogs');
        setBlogs(response.data);
      } catch (err) {
        console.error('Error fetching data', err);
      }
    };

    fetchBlogs();
  }, []);

  const handleEdit = (id) => {
    navigate(`/dashboard?edit=${id}`);
  };

  const handleDelete = async (id) => {
    try {
      await axiosInstance.delete(`/blogs/${id}`);
      const response = await axiosInstance.get('/blogs');
      setBlogs(response.data);
    } catch (err) {
      console.error('Error deleting blog', err);
    }
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>Blog Report</Typography>
      <Grid container spacing={2}>
        {blogs.map((blog) => (
          <Grid item xs={12} sm={6} md={4} key={blog._id}>
            <div>
              <img src={blog.image} alt={blog.title} style={{ width: '100%' }} />
              <Typography variant="h6">{blog.title}</Typography>
              <Typography>{blog.description}</Typography>
              <Button onClick={() => handleEdit(blog._id)} variant="contained" color="secondary">
                Edit
              </Button>
              <Button onClick={() => handleDelete(blog._id)} variant="contained" color="error">
                Delete
              </Button>
            </div>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default BlogReport;
