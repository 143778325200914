import React, { useState } from "react";
import { Link } from "react-router-dom";

const Header = () => {
    const [isSearchOpen, setIsSearchOpen] = useState(false);
    const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false);
    const [openDropdown, setOpenDropdown] = useState(null);
    const [openSubDropdown, setOpenSubDropdown] = useState({});
  
    const handleToggle = (index) => {
      setOpenDropdown(openDropdown === index ? null : index);
    };
  
    const handleSubToggle = (parentIndex, index) => {
      setOpenSubDropdown({
        ...openSubDropdown,
        [parentIndex]: openSubDropdown[parentIndex] === index ? null : index
      });
    };
    const handleSearchOpen = () => {
        setIsSearchOpen(true);
      };

    const handleSearchClose = () => {
      setIsSearchOpen(false);
    };

    const handleOffcanvasOpen = () => {
      setIsOffcanvasOpen(true);
    };
  
    const handleOffcanvasClose = () => {
      setIsOffcanvasOpen(false);
    };
  return (
    <>
      {/* <div id="preloader">
        <div class="preloader-close">x</div>
        <div class="sk-three-bounce">
          <div class="sk-child sk-bounce1"></div>
          <div class="sk-child sk-bounce2"></div>
          <div class="sk-child sk-bounce3"></div>
        </div>
      </div> */}

      {isOffcanvasOpen && (
        <div className="fix" data-bs-interval="1000">
          <div className="offcanvas__area info-close info-open">
            <div className="offcanvas__wrapper">
              <div className="offcanvas__content">
                <div className="offcanvas__top d-flex justify-content-between align-items-center">
                  <div className="offcanvas__logo">
                    <Link to="/">
                      <img src="/assets/imgs/logo/Logo_white.png" style={{ width:'220px'}} alt="logo not found" />
                      </Link>
                  </div>
                  <div className="offcanvas__close">
                    <button className="offcanvas-close-icon animation--flip" onClick={handleOffcanvasClose}>
                      <span className="offcanvas-m-lines">
                        <span className="offcanvas-m-line line--1"></span>
                        <span className="offcanvas-m-line line--2"></span>
                        <span className="offcanvas-m-line line--3"></span>
                      </span>
                    </button>
                  </div>
                </div>
                <div className=" mobile-menu fix mean-container">
                    
                </div>
                <div className="mobile-menu fix mean-container">
                <div className="mean-bar">
                    <a href="#nav" className="meanmenu-reveal" style={{ right: "0px", left: "auto", display: "inline" }}>
                    <span><span><span></span></span></span>
                    </a>
                    <nav className="mean-nav">
                    <ul>
                        <li className="has-mega-menu active">
                        <Link to="/">Home</Link>
                        </li>
                        <li className="has-dropdown">
                        <Link to="javascript:void(0)" onClick={() => handleToggle(0)}>About us</Link>
                        <a className="mean-expand" href="#" style={{ fontSize: "18px" }} onClick={() => handleToggle(0)}>
                            <i className={`fa-regular fa-angle-right ${openDropdown === 0 ? 'open' : ''}`}></i>
                        </a>
                        <ul className="submenu" style={{ display: openDropdown === 0 ? 'block' : 'none' }}>
                                         <li>  <Link to="/square-medtech">SQUARE MEDTECH</Link></li>
                                          <li><Link to="/therapeutic-specialties">Therapeutic specialties</Link></li>
                                            <li>  <Link to="/how-we-work">How We Work </Link></li>
                                            <li>  <Link to="/faq">FAQ</Link></li>
                        </ul>
                        </li>
                        <li className="has-dropdown">
                        <Link to=" " onClick={() => handleToggle(1)}>Solutions</Link>
                        <a className="mean-expand" href="#" style={{ fontSize: "18px" }} onClick={() => handleToggle(1)}>
                            <i className={`fa-regular fa-angle-right ${openDropdown === 1 ? 'open' : ''}`}></i>
                        </a>
                        <ul className="submenu" style={{ display: openDropdown === 1 ? 'block' : 'none' }}>
                            <li><Link to="/service">Medical Device Manufacturers</Link></li>
                            <li><Link to="/service-details">Pharmaceutical & Biotech industry</Link></li>
                            <li><Link to="/doctor">Healthcare Providers</Link></li>
                        </ul>
                        </li>
                        <li className="has-dropdown">
                        <Link to=" " onClick={() => handleToggle(2)}>Products & Service</Link>
                        <a className="mean-expand" href="#" style={{ fontSize: "18px" }} onClick={() => handleToggle(2)}>
                            <i className={`fa-regular fa-angle-right ${openDropdown === 2 ? 'open' : ''}`}></i>
                        </a>
                        <ul className="submenu" style={{ display: openDropdown === 2 ? 'block' : 'none' }}>
                            <li>
                            <Link to="" onClick={() => handleSubToggle(2, 0)}>Medical Device consulting</Link>
                            <a className="mean-expand" href="#" style={{ fontSize: "18px" }} onClick={() => handleSubToggle(2, 0)}>
                                <i className={`fa-regular fa-angle-right ${openSubDropdown[2] === 0 ? 'open' : ''}`}></i>
                            </a>
                            <ul className="submenu" style={{ display: openSubDropdown[2] === 0 ? 'block' : 'none' }}>
                                <li><Link to="/doctor-details">Quality Management System</Link></li>
                                <li><Link to="/doctor-details">Technical Documentation</Link></li>
                                <li><Link to="/doctor-details">Post Market Activities</Link></li>
                                <li><Link to="/doctor-details">Quality Assurance /Regulatory Affairs Service</Link></li>
                                <li><Link to="/doctor-details">Product Registrations</Link></li>
                                <li><Link to="/doctor-details">Data protection</Link></li>
                                <li><Link to="/doctor-details">Regulatory Life Cycle</Link></li>
                            </ul>
                            </li>
                            <li>
                            <Link to="" onClick={() => handleSubToggle(2, 1)}>CRO</Link>
                            <a className="mean-expand" href="#" style={{ fontSize: "18px" }} onClick={() => handleSubToggle(2, 1)}>
                                <i className={`fa-regular fa-angle-right ${openSubDropdown[2] === 1 ? 'open' : ''}`}></i>
                            </a>
                            <ul className="submenu" style={{ display: openSubDropdown[2] === 1 ? 'block' : 'none' }}>
                                <li><Link to="/doctor-details">Clinical Evaluation</Link></li>
                                <li><Link to="/doctor-details">Clinical Investigation</Link></li>
                                <li><Link to="/doctor-details">PMCF Solution</Link></li>
                            </ul>
                            </li>
                            <li>
                            <Link to="" onClick={() => handleSubToggle(2, 2)}>Healthcare Consulting</Link>
                            <a className="mean-expand" href="#" style={{ fontSize: "18px" }} onClick={() => handleSubToggle(2, 2)}>
                                <i className={`fa-regular fa-angle-right ${openSubDropdown[2] === 2 ? 'open' : ''}`}></i>
                            </a>
                            <ul className="submenu" style={{ display: openSubDropdown[2] === 2 ? 'block' : 'none' }}>
                                <li><Link to="/doctor-details">EU MDR/FDA/ NMPA Consulting</Link></li>
                                <li><Link to="/doctor-details">Healthcare Survey</Link></li>
                            </ul>
                            </li>
                        </ul>
                        </li>
                        <li className="has-dropdown">
                        <Link to=""  onClick={() => handleToggle(3)}>Knowledge Center</Link>
                        <a className="mean-expand" href="#" style={{ fontSize: "18px" }} onClick={() => handleToggle(3)}>
                            <i className={`fa-regular fa-angle-right ${openDropdown === 3 ? 'open' : ''}`}></i>
                        </a>
                        <ul className="submenu" style={{ display: openDropdown === 3 ? 'block' : 'none' }}>
                            <li><Link to="/regulations">Regulations</Link></li>
                            <li><Link to="/acronyms-abbreviations">Acronyms & Abbreviations</Link></li>
                            <li><Link to="/training">Training</Link></li>
                            <li><Link to="/portfolio-details">Videos</Link></li>
                        </ul>
                        </li>
                        <li>
                        <Link to="/blog">Blogs</Link>
                        </li>
                        <li><Link to="/contact-us#">Contact</Link></li>
                    </ul>
                    </nav>
                </div>
                </div>
                <div className="offcanvas__social">
                  <h4 className="offcanvas__title mb-20">Subscribe & Follow</h4>
                  <p className="mb-30">Medical practices evolved over millennia, from ancient civilizations like Egypt and Mesopotamia to the groundbreaking</p>
                  <ul className="header-top-socail-menu d-flex">
                    <li><a href="https://www.facebook.com/"><i className="fab fa-facebook-f"></i></a></li>
                    <li><a href="https://twitter.com/"><i className="fab fa-twitter"></i></a></li>
                    <li><a href="https://www.pinterest.com/"><i className="fab fa-pinterest-p"></i></a></li>
                    <li><a href="https://vimeo.com/"><i className="fab fa-vimeo-v"></i></a></li>
                  </ul>
                </div>
                <div className="offcanvas__btn d-sm-none">
                  <div className="header__btn-wrap">
                    <a href="https://themeforest.net/user/rrdevs/portfolio" className="rr-btn rr-btn__theme rr-btn__theme-white mt-40 mt-sm-35 mt-xs-30">
                      <span className="btn-wrap">
                        <span className="text-one">Purchase Now</span>
                        <span className="text-two">Purchase Now</span>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="offcanvas__overlay overlay-open" onClick={handleOffcanvasClose}></div>
        </div>
      )}

         {isSearchOpen && (
        <div className="search__popup">
          <div className="container">
            <div className="row gx-30">
              <div className="col-xxl-12">
                <div className="search__wrapper">
                  <div className="search__top d-flex justify-content-between align-items-center">
                    <div className="search__logo">
                      <a href="index">
                        <img src="/assets/imgs/logo/logo-white.svg" alt="img" />
                      </a>
                    </div>
                    <div className="search__close">
                      <button
                        type="button"
                        className="search__close-btn search-close-btn"
                        onClick={handleSearchClose}
                      >
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
                          <path
                            d="M17 1L1 17"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M1 1L17 17"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div className="search__form">
                    <form action="#">
                      <div className="search__input">
                        <input
                          className="search-input-field"
                          type="text"
                          placeholder="Type here to search..."
                        />
                        <span className="search-focus-border"></span>
                        <button type="submit">
                          <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path
                              d="M9.55 18.1C14.272 18.1 18.1 14.272 18.1 9.55C18.1 4.82797 14.272 1 9.55 1C4.82797 1 1 4.82797 1 9.55C1 14.272 4.82797 18.1 9.55 18.1Z"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M19.0002 19.0002L17.2002 17.2002"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}


<div class="loading-form">
    <div class="sk-three-bounce">
        <div class="sk-child sk-bounce1"></div>
        <div class="sk-child sk-bounce2"></div>
        <div class="sk-child sk-bounce3"></div>
    </div>
</div>

<div id="scroll-percentage">
    <span id="scroll-percentage-value"></span>
</div>

<div class="fix">
    <div class="offcanvas__area">
        <div class="offcanvas__wrapper">
            <div class="offcanvas__content">
                <div class="offcanvas__top d-flex justify-content-between align-items-center">
                    <div class="offcanvas__logo">
                        <a href="index">
                            <img src="/assets/imgs/logo/logo-white.svg" alt="logo not found"/>
                        </a>
                    </div>
                    <div class="offcanvas__close">
                        <button class="offcanvas-close-icon animation--flip">
                                <span class="offcanvas-m-lines">
                              <span class="offcanvas-m-line line--1"></span><span class="offcanvas-m-line line--2"></span><span class="offcanvas-m-line line--3"></span>
                                </span>
                        </button>
                    </div>
                </div>
                <div class="mobile-menu fix"></div> 
                <div class="offcanvas__social">
                    <h4 class="offcanvas__title mb-20">Subscribe & Follow</h4>
                    <p class="mb-30">Medical practices evolved over millennia, from ancient civilizations like Egypt and Mesopotamia to the groundbreaking</p>
                    <ul class="header-top-socail-menu d-flex">
                        <li><a href="https://www.facebook.com/"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="https://twitter.com/"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="https://www.pinterest.com/"><i class="fa-brands fa-pinterest-p"></i></a></li>
                        <li><a href="https://vimeo.com/"><i class="fa-brands fa-vimeo-v"></i></a></li>
                    </ul>
                </div>
                <div class="offcanvas__btn d-sm-none">
                    <div class="header__btn-wrap">
                        <a href="https://themeforest.net/user/rrdevs/portfolio" class="rr-btn rr-btn__theme rr-btn__theme-white mt-40 mt-sm-35 mt-xs-30">
                            <span class="btn-wrap">
                                <span class="text-one">Purchase Now</span>
                                <span class="text-two">Purchase Now</span>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="offcanvas__overlay"></div>
<div class="offcanvas__overlay-white"></div>

<header>
    <div id="header-sticky" class="header__area header-2">
        <div class="container">
            <div class="mega__menu-wrapper p-relative">
                <div class="header__main">
                    <div class="header__left d-flex align-items-center">
                        <div class="header__logo">
                            <a >
                                <div class="logo">
                                    <img src="/assets/imgs/logo/Logo_white.png" alt="logo not found" style={{maxWidth:"200px"}}/>
                                </div>
                            </a>
                        </div>

                        <div class="mean__menu-wrapper d-none d-xl-block">
                        <div class="main-menu main-menu-2">
                            <nav id="mobile-menu">
                                <ul>
                                    <li class=" has-mega-menu active">
                                    <Link to="/">Home</Link>
                                    </li>
                                    <li class="has-dropdown ">
                                    <Link to="javascript:void(0)">About us</Link>
                                        <ul class="submenu">
                                        <li>  <Link to="/square-medtech">SQUARE MEDTECH</Link></li>
                                          <li><Link to="/therapeutic-specialties">Therapeutic specialties</Link></li>
                                            <li>  <Link to="/how-we-work">How We Work </Link></li>
                                            <li>  <Link to="/faq">FAQ</Link></li>
                                        </ul>
                                    </li>
                                    <li class="has-dropdown">
                                    <Link to="service">Solutions</Link>
                                        <ul class="submenu">
                                            <li>  <Link to="service">Medical Device Manufacturers </Link></li>
                                            <li>  <Link to="service-details">Pharmaceutical & Biotech industry </Link></li>
                                            <li>  <Link to="doctor">Healthcare Providers</Link></li>
                                        </ul>
                                    </li>
                                    <li class="has-dropdown">
                                    <Link to="doctor">Products & Service </Link>
                                        <ul class="submenu">
                                        <li>  <Link to="doctor-details">	Medical Device consulting</Link>
                                        <ul class="submenu">
                                           <li>  <Link to="doctor-details">Quality Management System</Link></li>
                                            <li>  <Link to="doctor-details">Technical Documentation</Link></li>
                                            <li>  <Link to="doctor-details">Post Market Activities</Link></li>
                                            <li>  <Link to="doctor-details">Quality Assurance /Regulatory Affairs Service</Link></li>
                                            <li>  <Link to="doctor-details">Product Registrations</Link></li>
                                            <li>  <Link to="doctor-details">Digital Health Services </Link></li>
                                            <li>  <Link to="doctor-details">Data protection</Link></li>
                                            <li>  <Link to="doctor-details">Regulatory Life Cycle</Link></li>
                                                </ul> 
                                            </li>
                                            <li>  <Link to="doctor-details">CRO</Link>
                                        <ul class="submenu">
                                           <li>  <Link to="doctor-details">Clinical Evaluation</Link></li>
                                            <li>  <Link to="doctor-details">Clinical Investigation </Link></li>
                                            <li>  <Link to="doctor-details">PMCF Solution </Link></li>
                                                </ul> 
                                            </li>
                                            <li>  <Link to="doctor-details">	Healthcare Consulting</Link>
                                        <ul class="submenu">
                                           <li>  <Link to="doctor-details">EU MDR/FDA/ NMPA Consulting  </Link></li>
                                            <li>  <Link to="doctor-details">	Healthcare Survey</Link></li>
                                                </ul> 
                                                
                                            </li>
                                        </ul>
                                        
                                    </li>
                                    <li class="has-dropdown">
                                    <Link to="portfolio">Resources</Link>
                                        <ul class="submenu">
                                            <li>  <Link to="/regulations">Regulations</Link></li>
                                            <li>  <Link to="/acronyms-abbreviations">Acronyms & Abbreviations</Link></li>
                                            <li>  <Link to="/training">Training</Link></li>
                                            <li>  <Link to="portfolio-details"> Videos</Link></li>
                                        </ul>
                                    </li>
                                    <li class="has-">
                                    <Link to="/blog">Blogs</Link>
                                        {/* <ul class="submenu">
                                            <li><a to="blog">Blog</Link></li>
                                            <li><a to="blog-details">Blog Details</Link></li>
                                        </ul> */}
                                    </li>
                                    <li>  <Link to="/contact-us#">contact</Link></li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                    </div>

                    <div class="header__right">
                        <div class="header__action d-flex align-items-center">
                            <div class="header__btn-wrap align-items-center d-inline-flex">
                            <div className="rr-header-icon-search">
                                <button className="search-open-btn" onClick={handleSearchOpen}>
                                <i className="fa-solid fa-magnifying-glass"></i>
                                </button>
                            </div>

                                <a href="contact" class="rr-header-message d-none d-sm-block"><i class="fa-regular fa-message"></i></a>
                                <div class="rr-header-contact-btn d-flex align-items-center d-none d-lg-flex">
                                    <div class="rr-header-contact-btn__icon">
                                        <i class="fa-solid fa-phone"></i>
                                    </div>
                                    <div class="rr-header-contact-btn__text">
                                        <p class="mb-0 color-white">Need help?</p>
                                        <h5 class="mb-0 color-white"><a href="tel:+86 19811976744">(+86) 19811976744</a></h5>
                                    </div>
                                </div>
                            </div>

                            {/* <div class="header__hamburger ml-30 d-xl-none">
                                <div class="sidebar__toggle">
                                    <a class="bar-icon" href="javascript:void(0)">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </a>
                                </div>
                            </div> */}
                            <div className="header__hamburger  ml-30 mr-10 d-xl-none">
                                <div className="sidebar__toggle">
                                <a className="bar-icon" href="javascript:void(0)" onClick={handleOffcanvasOpen}>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </a>
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>
    </>
  );
};

export default Header;
