import React from 'react'
import HeaderMain from '../../../Compoents/HeaderMain'
import FooterWhite from '../../../Compoents/FooterWhite'
import BreadcrumbArea from '../../../ShearOne/BreadcrumbArea';
import Appoinment from '../../Home/HomeInner/Appoinment';

export const FAQ = () => {
    const breadcrumbLinks = [
        { path: '/', label: 'Home' },
        { label: 'FAQ', active: true }
      ];
  return (
 <>
    <HeaderMain/>
    <BreadcrumbArea 
     title="FAQ" 
     backgroundImage="/assets/imgs/breadcrumb/page-header-1.png" 
     links={breadcrumbLinks} 
   />
   <FAQSection/>
   <Appoinment/>
   <FocusPoint/>
        <FooterWhite/>
    </>
  )
}

export const FAQSection = () => {
    return (
      <>
        <section class="faq-page section-space">
          <div class="container">
            <div class="row" id="accordionExample">
              <h3>Frequently Asked Questions (FAQs) for SQUARE MEDTECH:</h3>
              <div class="col-lg-6 py-5">
                <div class="rr__faq-2">
                  <div class="accordion">
                    <div class="accordion-item">
                      <h5 class="accordion-header" id="headingOne">
                        <button
                          class="accordion-button collapse"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          What is SQUARE MEDTECH?
                        </button>
                      </h5>
                      <div
                        id="collapseOne"
                        class="accordion-collapse collapse show"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          <p>
                            SQUARE MEDTECH is a research organization that provides comprehensive services for Clinical Evaluation, Clinical Investigation, PMCF solutions, Technical Documentations, Study Design, Regulatory Compliance, Data Management, Statistical analysis, EU MDR, FDA, NMPA consulting and healthcare survey, to support the development of Medical Devices, Pharmaceuticals & Biotech, and Healthcare providers.
                          </p>
                        </div>
                      </div>
                    </div>
  
                    <div class="accordion-item">
                      <h5 class="accordion-header" id="headingTwo">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          Where is SQUARE MEDTECH located?
                        </button>
                      </h5>
                      <div
                        id="collapseTwo"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingTwo"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          <p>
                            SQUARE MEDTECH CRO is headquartered in Shenzhen China, and offers facilities around the globe.
                          </p>
                        </div>
                      </div>
                    </div>
  
                    <div class="accordion-item">
                      <h5 class="accordion-header" id="headingThree">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          What services does SQUARE MEDTECH offer?
                        </button>
                      </h5>
                      <div
                        id="collapseThree"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          <p>
                            Our services include Clinical Evaluation, Clinical Investigation, Post-market Surveillance, Technical Documentations, Medical Writing, Study Design, Regulatory consulting, Data Management, Statistical analysis, EU MDR, FDA, NMPA consulting, and healthcare survey.
                          </p>
                        </div>
                      </div>
                    </div>
  
                    <div class="accordion-item">
                      <h5 class="accordion-header" id="headingFour">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFour"
                          aria-expanded="false"
                          aria-controls="collapseFour"
                        >
                          Do you specialize in any particular therapeutic areas?
                        </button>
                      </h5>
                      <div
                        id="collapseFour"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingFour"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          <p>
                            Yes, we specialize in a wide range of therapeutic areas including Cardiovascular, Vascular, Medical Imaging, Oral care, Surgical, Orthopedics, Respiratory Care, Wound Care, Oncology, Neurology, Nephrology, Urology, Vestibular, Device for Temperature Management, Pharmaceuticals & Biotech products, and more.
                          </p>
                        </div>
                      </div>
                    </div>
  
                    <div class="accordion-item">
                      <h5 class="accordion-header" id="headingFive">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFive"
                          aria-expanded="false"
                          aria-controls="collapseFive"
                        >
                          Can SQUARE MEDTECH help with both pre-market and post-market studies?
                        </button>
                      </h5>
                      <div
                        id="collapseFive"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingFive"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          <p>
                            Yes, we provide support for both pre-market clinical investigations and post-market surveillance studies to ensure the safety and efficacy of medical products.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
  
              <div class="col-lg-6 py-5">
                <div class="rr__faq-2">
                  <div class="accordion">
                    <div class="accordion-item">
                      <h5 class="accordion-header" id="headingSix">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseSix"
                          aria-expanded="false"
                          aria-controls="collapseSix"
                        >
                          How does SQUARE MEDTECH ensure regulatory compliance?
                        </button>
                      </h5>
                      <div
                        id="collapseSix"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingSix"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          <p>
                            We have a dedicated regulatory affairs team that stays up-to-date with global regulations and guidelines to ensure all our evaluation & investigations are compliant with EU MDR, FDA, NMPA, and other regulatory bodies.
                          </p>
                        </div>
                      </div>
                    </div>
  
                    <div class="accordion-item">
                      <h5 class="accordion-header" id="headingSeven">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseSeven"
                          aria-expanded="false"
                          aria-controls="collapseSeven"
                        >
                          What data management systems do you use?
                        </button>
                      </h5>
                      <div
                        id="collapseSeven"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingSeven"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          <p>
                            We utilize industry-leading electronic data capture (EDC) systems and have robust data management processes to ensure data integrity and confidentiality.
                          </p>
                        </div>
                      </div>
                    </div>
  
                    <div class="accordion-item">
                      <h5 class="accordion-header" id="headingEight">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseEight"
                          aria-expanded="false"
                          aria-controls="collapseEight"
                        >
                          How do you handle data analysis and reporting?
                        </button>
                      </h5>
                      <div
                        id="collapseEight"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingEight"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          <p>
                            Our biostatistics team uses advanced statistical software and methodologies to analyze trial data, and our medical writers prepare comprehensive and regulatory-compliant reports.
                          </p>
                        </div>
                      </div>
                    </div>
  
                    <div class="accordion-item">
                      <h5 class="accordion-header" id="headingNine">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseNine"
                          aria-expanded="false"
                          aria-controls="collapseNine"
                        >
                          How does SQUARE MEDTECH CRO communicate with sponsors?
                        </button>
                      </h5>
                      <div
                        id="collapseNine"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingNine"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          <p>
                            We maintain open and transparent communication with sponsors through regular updates, meetings, and detailed reports to ensure alignment and address any concerns promptly.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  };
  
  

export const FocusPoint = () => {
    return (
      <>
          <section class="footer__cta footer__cta-bottom-up">
      <div class="container">
          <div class="row mb-minus-30">
              <div class="col-md-6">
                  <div class="footer__cta-item mb-30 theme-bg-primary d-flex flex-lg-row flex-column align-items-lg-center">
                      <div class="footer__cta-item-icon d-flex align-items-center justify-content-center">
                          <img class="img-fluid" src="assets/imgs/footer__cta/footer__cta-item-1.png" alt="icon not found"/>
                      </div>
  
                      <div class="footer__cta-item-text">
                          <h4 class="color-white mb-15 mb-10">Our Team Expertise </h4>
                          <ul class="color-white mb-0">
                          <li>Clinical Research</li>
                            <li>Study Design</li>
                            <li>Regulatory Affairs</li>
                          </ul>
  
                      </div>
                  </div>
              </div>
  
              <div class="col-md-6">
                  <div class="footer__cta-item mb-30 theme-bg-glow d-flex flex-lg-row flex-column align-items-lg-center">
                      <div class="footer__cta-item-icon d-flex align-items-center justify-content-center">
                          <img class="img-fluid" src="assets/imgs/footer__cta/footer__cta-item-2.png" alt="icon not found"/>
                      </div>
  
                      <div class="footer__cta-item-text">
                          <h4 class="color-white mb-15 mb-10">Our Team Expertise </h4>
                          <ul class="color-white mb-0">
                          <li>Biostatistics</li>
                            <li>Medical Writing</li>
                            <li>Healthcare Survey</li>
                          </ul>
  
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </section>
      </>
    )
  }