import React from "react";
import AppointmentForm, { ContactForm } from "../../Contact/AppointmentForm";


const Appoinment = () => {
  return (
    <>
      <section class="appointment-2 appointment-2__background section-space overflow-hidden">
        <div class="container">
          <div class="row align-items-end mb-60 mb-sm-50 mb-xs-40">
            <div class="col-lg-6">
              <div class="section__title-wrapper appointment-2__content">
                <h5 class="section__subtitle color-theme-primary mb-15 mb-xs-10 title-animation">
                  <img
                    src="assets/imgs/ask-quesiton/heart.png"
                    alt="icon not found"
                    class="img-fluid"
                  />{" "}
                  Appointment
                </h5>
                <h2 class="section__title mb-0 title-animation">
                  Surging Toward Healthy Horizon Waves
                </h2>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="appointment-2__content-right">
                <p class="mb-0">
                At SQUARE MEDTECH, we are passionate about making a difference in the world of medical research. Take an appointment with our mission to advance healthcare and improve lives through innovative clinical research.
                </p>
              </div>
            </div>
          </div>

          <div class="row align-items-center">
            <div class="col-lg-6">
            <ContactForm/>
              {/* <div class="appointment-2__form position-relative">
                <div class="row">
                  <div class="col-sm-6">
                    <div class="appointment-2__form-input">
                      <label for="name">Your name</label>
                      <div class="input-wrapper">
                        <input
                          name="name"
                          id="name"
                          type="text"
                          placeholder="Your name..."
                        />
                        <i class="fa-solid fa-user"></i>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="appointment-2__form-input">
                      <label for="phone">Your Phone</label>
                      <div class="input-wrapper">
                        <input
                          name="phone"
                          id="phone"
                          type="text"
                          placeholder="Your phone..."
                        />
                        <i class="fa-solid fa-phone"></i>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="appointment-2__form-input">
                      <label for="phone">health type</label>
                      <div class="appointment-2__form-input-select">
                        <select id="health" style={{display:"none"}}>
                          <option>Choose one...</option>
                          <option>Good</option>
                          <option>Very Good</option>
                          <option>Week</option>
                          <option>Very Week</option>
                        </select>
                        <div class="nice-select" tabindex="0">
                          <span class="current">Choose one...</span>
                          <ul class="list">
                            <li
                              data-value="Choose one..."
                              class="option selected focus"
                            >
                              Choose one...
                            </li>
                            <li data-value="Good" class="option">
                              Good
                            </li>
                            <li data-value="Very Good" class="option">
                              Very Good
                            </li>
                            <li data-value="Week" class="option">
                              Week
                            </li>
                            <li data-value="Very Week" class="option">
                              Very Week
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="appointment-2__form-input">
                      <label for="email">Your Email</label>
                      <div class="input-wrapper">
                        <input
                          name="email"
                          id="email"
                          type="text"
                          placeholder="Your email..."
                        />
                        <i class="fa-solid fa-paper-plane"></i>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="appointment-2__form-input">
                      <label for="phone">Select doctor</label>
                      <div class="appointment-2__form-input-select">
                        <select id="doctor" style={{display:"none"}}>
                          <option>Your doctor...</option>
                          <option>Dr. Paul</option>
                          <option>Dr. Sabbir</option>
                          <option>Dr. Rubel</option>
                        </select>
                        <div class="nice-select" tabindex="0">
                          <span class="current">Your doctor...</span>
                          <ul class="list">
                            <li
                              data-value="Your doctor..."
                              class="option selected"
                            >
                              Your doctor...
                            </li>
                            <li data-value="Dr. Paul" class="option">
                              Dr. Paul
                            </li>
                            <li data-value="Dr. Sabbir" class="option">
                              Dr. Sabbir
                            </li>
                            <li data-value="Dr. Rubel" class="option">
                              Dr. Rubel
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="appointment-2__form-input">
                      <label for="datepicker">Select Date</label>
                      <div class="input-wrapper">
                        <input
                          id="datepicker"
                          name="date"
                          type="text"
                          placeholder="YY/MM/DD"
                        />
                        <i class="fa-solid fa-calendar-days"></i>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <button
                      type="submit"
                      class="rr-btn rr-btn__primary-color mt-0"
                    >
                      <span class="btn-wrap">
                        <span class="text-one">
                          Appointment now <i class="fa-solid fa-plus"></i>
                        </span>
                        <span class="text-two">
                          Appointment now <i class="fa-solid fa-plus"></i>
                        </span>
                      </span>
                    </button>
                  </div>
                </div>
              </div> */}
            </div>
            <div class="col-lg-6">
              <div class="appointment-2__media">
                <img
                  class="img-fluid"
                  src="assets/imgs/appointment/appointment.png"
                  alt="icon not found"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Appoinment;
