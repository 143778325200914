import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import PhoneInput from 'react-phone-input-2'

const AppointmentForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    companyName: '',
    email: '',
    phone: '',
    consultationType: '',
    message: '',
    image: null,
  });
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      files: e.target.files,
    });
  };

  const handleDropdownChange = (value) => {
    setFormData({
      ...formData,
      consultationType: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const data = new FormData();
    data.append('name', formData.name);
    data.append('companyName', formData.companyName);
    data.append('email', formData.email);
    data.append('phone', formData.phone);
    data.append('consultation_type', formData.consultationType);
    data.append('message', formData.message);

    if (formData.files) {
      Array.from(formData.files).forEach((file) => data.append('image', file));
    }

    try {
      const response = await axios.post(
        'https://api.squaremedtech.com/api/contact',
        data,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      );
      setResult({ success: true, message: 'Form submitted successfully!' });
      setFormData({
        name: '',
        companyName: '',
        email: '',
        phone: '',
        consultationType: '',
        message: '',
        image: null,
      });
    } catch (error) {
      setResult({
        success: false,
        message: error.response?.data?.response || 'Form submission failed',
      });
    } finally {
      setLoading(false);
    }
  };

  
  return (
    <div className="appointment-2__form position-relative">
     <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-sm-6">
            <div className="appointment-2__form-input">
              <label htmlFor="name">Your Name*</label>
              <div className="input-wrapper">
                <input
                  id="name"
                  type="text"
                  placeholder="Your name..."
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                />
                <i className="fa-solid fa-user"></i>
              </div>
            </div>
          </div>

          <div className="col-sm-6">
            <div className="appointment-2__form-input">
              <label htmlFor="companyName">Company Name</label>
              <div className="input-wrapper">
                <input
                  id="companyName"
                  type="text"
                  placeholder="Company name..."
                  name="companyName"
                  value={formData.companyName}
                  onChange={handleInputChange}
                />
                <i className="fa-solid fa-building"></i>
              </div>
            </div>
          </div>

          <div className="col-sm-6">
            <div className="appointment-2__form-input">
              <label htmlFor="email">Your Email*</label>
              <div className="input-wrapper">
                <input
                  id="email"
                  type="email"
                  placeholder="Your email..."
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
                <i className="fa-solid fa-paper-plane"></i>
              </div>
            </div>
          </div>

          <div className="col-sm-6">
            <div className="appointment-2__form-input">
              <label htmlFor="phone">Phone Number*</label>
              <div className="input-wrapper">
                <input
                  id="phone"
                  type="text"
                  placeholder="Your phone..."
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                  required
                />
                <i className="fa-solid fa-phone"></i>
              </div>
            </div>
          </div>

          <div className="col-sm-12">
            <div className="appointment-2__form-input">
              <label htmlFor="consultationType">Consultation Type*</label>
              <div className="appointment-2__form-input-select">
                <select
                  id="consultationType"
                  name="consultationType"
                  value={formData.consultationType}
                  onChange={(e) => handleDropdownChange(e.target.value)}
                  style={{ display: 'none' }}
                >
                  <option value="">Choose one...</option>
                  <option value="Quality Management System">Quality Management System</option>
                  <option value="Technical Documentation">Technical Documentation</option>
                  <option value="Post Market Activities">Post Market Activities</option>
                  <option value="Quality Assurance /Regulatory Affairs Service">Quality Assurance /Regulatory Affairs Service</option>
                  <option value="Product Registrations">Product Registrations</option>
                  <option value="Digital Health Services">Digital Health Services</option>
                  <option value="Data protection">Data protection</option>
                  <option value="Regulatory Life Cycle">Regulatory Life Cycle</option>
                  <option value="Clinical Evaluation">Clinical Evaluation</option>
                  <option value="Clinical Investigation">Clinical Investigation</option>
                  <option value="PMCF Solution">PMCF Solution</option>
                  <option value="EU MDR/FDA/ NMPA Consulting">EU MDR/FDA/ NMPA Consulting</option>
                  <option value="Healthcare Survey">Healthcare Survey</option>
                  <option value="Others">Others</option>
                </select>
                <div className="nice-select" tabIndex="0">
                  <span className="current">{formData.consultationType || 'Choose one...'}</span>
                  <ul className="list">
                    <li
                      data-value=""
                      className={`option ${!formData.consultationType ? 'selected' : ''}`}
                      onClick={() => handleDropdownChange('')}
                    >
                      Choose one...
                    </li>
                    <li data-value="Quality Management System" className="option" onClick={() => handleDropdownChange('Quality Management System')}>
                      Quality Management System
                    </li>
                    <li data-value="Technical Documentation" className="option" onClick={() => handleDropdownChange('Technical Documentation')}>
                      Technical Documentation
                    </li>
                    <li data-value="Post Market Activities" className="option" onClick={() => handleDropdownChange('Post Market Activities')}>
                      Post Market Activities
                    </li>
                    <li data-value="Quality Assurance /Regulatory Affairs Service" className="option" onClick={() => handleDropdownChange('Quality Assurance /Regulatory Affairs Service')}>
                      Quality Assurance /Regulatory Affairs Service
                    </li>
                    <li data-value="Product Registrations" className="option" onClick={() => handleDropdownChange('Product Registrations')}>
                      Product Registrations
                    </li>
                    <li data-value="Digital Health Services" className="option" onClick={() => handleDropdownChange('Digital Health Services')}>
                      Digital Health Services
                    </li>
                    <li data-value="Data protection" className="option" onClick={() => handleDropdownChange('Data protection')}>
                      Data protection
                    </li>
                    <li data-value="Regulatory Life Cycle" className="option" onClick={() => handleDropdownChange('Regulatory Life Cycle')}>
                      Regulatory Life Cycle
                    </li>
                    <li data-value="Clinical Evaluation" className="option" onClick={() => handleDropdownChange('Clinical Evaluation')}>
                      Clinical Evaluation
                    </li>
                    <li data-value="Clinical Investigation" className="option" onClick={() => handleDropdownChange('Clinical Investigation')}>
                      Clinical Investigation
                    </li>
                    <li data-value="PMCF Solution" className="option" onClick={() => handleDropdownChange('PMCF Solution')}>
                      PMCF Solution
                    </li>
                    <li data-value="EU MDR/FDA/ NMPA Consulting" className="option" onClick={() => handleDropdownChange('EU MDR/FDA/ NMPA Consulting')}>
                      EU MDR/FDA/ NMPA Consulting
                    </li>
                    <li data-value="Healthcare Survey" className="option" onClick={() => handleDropdownChange('Healthcare Survey')}>
                      Healthcare Survey
                    </li>
                    <li data-value="Others" className="option" onClick={() => handleDropdownChange('Others')}>
                      Others
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12">
            <div className="appointment-2__form-input">
              <label htmlFor="message">Message</label>
              <div className="input-wrapper">
                <textarea
                  id="message"
                  placeholder="Your message..."
                  name="message"
                  value={formData.message}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>

          <div className="col-12">
            <div className="appointment-2__form-input">
              <label htmlFor="files">Choose Files</label>
              <input
                type="image"
                id="image"
                multiple
                onChange={handleFileChange}
              />
              <p>Maximum of 5 files with a maximum size of 25 MBs; supported formats are (DOC, DOCX, GIF, PDF, PNG, JPG, JPEG, TXT, XLS, XLXS).</p>
            </div>
          </div>

          <div className="col-12">
            <button
              type="submit"
              className="rr-btn rr-btn__primary-color mt-0"
              disabled={loading}
            >
              <span className="btn-wrap">
                <span className="text-one">
                  {loading ? 'Submitting...' : 'Submit'}
                </span>
                <span className="text-two">
                  {loading ? 'Submitting...' : 'Submit'}
                </span>
              </span>
            </button>
          </div>
        </div>
      </form>

      <div className={`result ${result?.success ? 'success' : 'error'}`}>
        {result?.message}
      </div>
      <br/><br/><br/><br/><br/><br/>
    </div>
  );
};

export default AppointmentForm;



export const ContactForm = () => {
  const [phone, setPhone] = useState('');
  const [formData, setFormData] = useState({
      name: '',
      email: '',
      phone: '',
      consultation_type: '',
      message: '',
      selectedFile: null,
  });
  const [errors, setErrors] = useState({});
  const maxSize = 25 * 1024 * 1024; // 25MB in bytes
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleDropdownChange = (value) => {
    setFormData({
      ...formData,
      consultationType: value,
    });
  };
  const handleInputChange = (e) => {
      setFormData({
          ...formData,
          [e.target.name]: e.target.value,
      });
  };

  const handleFileChange = (e) => {
      const file = e.target.files[0];
      if (file && file.size <= maxSize) {
          setFormData({
              ...formData,
              selectedFile: file,
          });
      } else {
          setErrors((prev) => ({
              ...prev,
              selectedFile: 'File size exceeds the 25MB limit.',
          }));
          
      }
  };

  const handlePhoneChange = (value) => {
      setPhone(value);
  };

  const validateForm = () => {
      const newErrors = {};
      if (!formData.name) newErrors.name = 'The name field is required.';
      if (!formData.email) newErrors.email = 'The email field is required.';
      if (!phone) newErrors.phone = 'The phone field is required.';
      if (!formData.message) newErrors.message = 'The message field is required.';
      if (!formData.selectedFile) newErrors.selectedFile = 'The image field is required.';

      setErrors(newErrors);
      return Object.keys(newErrors).length === 0;
  };

  const sendContactForm = async (e) => {
      e.preventDefault();

      if (!validateForm()) {
          return;
      }

      const formDataToSend = new FormData();
      formDataToSend.append('name', formData.name);
      formDataToSend.append('email', formData.email);
      formDataToSend.append('phone', phone);
      formDataToSend.append('consultation_type', formData.consultation_type);
      formDataToSend.append('message', formData.message);
      formDataToSend.append('image', formData.selectedFile);

      try {
          const response = await axios.post('https://api.squaremedtech.com/api/contact', formDataToSend, {
              headers: {
                  'Content-Type': 'multipart/form-data',
              },
          });

          if (response.status === 200) {
              alert('Message sent successfully!');
              setResult({
                success: false,
                message: errors.response?.data?.response || 'Form submission failed',
              });
              setFormData({
                  name: '',
                  email: '',
                  consultation_type: 'CLINICAL CONSULTATION',
                  message: '',
                  selectedFile: null,
              });
              setPhone('');
              setErrors({});
          } else {
              setErrors(response.data.response);
          }
      } catch (error) {
          console.error('Failed to send form:', error);
          alert('Failed to send the message. Please try again.');
      } finally {
        setLoading(false);
      }
  };

  return (
    <>
 <div className="appointment-2__form position-relative">
     <form onSubmit={sendContactForm}>
        <div className="row">
          <div className="col-sm-6">
            <div className="appointment-2__form-input">
              <label htmlFor="name">Your Name*</label>
              <div className="input-wrapper">
                <input
                  type="text"
                  placeholder='Your Name*'
                  name='name'
                  value={formData.name}
                  onChange={handleInputChange}
                  
              />
              {errors.name && <p className="error-text">{errors.name}</p>}
                <i className="fa-solid fa-user"></i>
              </div>
            </div>
          </div>

         

          <div className="col-sm-6">
            <div className="appointment-2__form-input">
              <label htmlFor="email">Your Email*</label>
              <div className="input-wrapper">
              <input
                type="email"
                placeholder='Your Email*'
                name='email'
                value={formData.email}
                onChange={handleInputChange}
                
            />
            {errors.email && <p className="error-text">{errors.email}</p>}
                <i className="fa-solid fa-paper-plane"></i>
              </div>
            </div>
          </div>
          <div className="col-sm-12" >
            <div className="appointment-2__form-input">
              <label htmlFor="companyName">Company Name</label>
              <div className="input-wrapper">
                <input
                  id="companyName"
                  type="text"
                  placeholder="Company name..."
                  name="companyName"
                  value={formData.companyName}
                />
                <i className="fa-solid fa-building"></i>
              </div>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="appointment-2__form-input">
              <label htmlFor="phone">Phone Number*</label>
              <div className="input-wrapper">
                {/* <input
                  id="phone"
                  type="text"
                  placeholder="Your phone..."
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                  required
                /> */}
                 <PhoneInput
                      placeholder="Enter phone number*"
                      value={phone}
                      onChange={handlePhoneChange}
                      
                  />
                  {errors.phone && <p className="error-text">{errors.phone}</p>}
                <i className="fa-solid fa-phone"></i>
              </div>
            </div>
          </div>

          <div className="col-sm-12">
            <div className="appointment-2__form-input">
              <label htmlFor="consultationType">Consultation Type*</label>
              <div className="appointment-2__form-input-select">
             
                <select
                  id="consultationType"
                  name="consultationType"
                  value={formData.consultation_type}
                  onChange={(e) => handleDropdownChange(e.target.value)}
                  style={{ display: 'none' }}
                >
                  <option value="">Choose one...</option>
                  <option value="Quality Management System">Quality Management System</option>
                  <option value="Technical Documentation">Technical Documentation</option>
                  <option value="Post Market Activities">Post Market Activities</option>
                  <option value="Quality Assurance /Regulatory Affairs Service">Quality Assurance /Regulatory Affairs Service</option>
                  <option value="Product Registrations">Product Registrations</option>
                  <option value="Digital Health Services">Digital Health Services</option>
                  <option value="Data protection">Data protection</option>
                  <option value="Regulatory Life Cycle">Regulatory Life Cycle</option>
                  <option value="Clinical Evaluation">Clinical Evaluation</option>
                  <option value="Clinical Investigation">Clinical Investigation</option>
                  <option value="PMCF Solution">PMCF Solution</option>
                  <option value="EU MDR/FDA/ NMPA Consulting">EU MDR/FDA/ NMPA Consulting</option>
                  <option value="Healthcare Survey">Healthcare Survey</option>
                  <option value="Others">Others</option>
                </select>
                <div className="nice-select" tabIndex="0">
                  <span className="current">{formData.consultation_type || 'Choose one...'}</span>
                  <ul className="list">
                    <li
                      data-value=""
                      className={`option ${!formData.consultation_type ? 'selected' : ''}`}
                      onClick={() => handleDropdownChange('')}
                    >
                      Choose one...
                    </li>
                    <li data-value="Quality Management System" className="option" onClick={() => handleDropdownChange('Quality Management System')}>
                      Quality Management System
                    </li>
                    <li data-value="Technical Documentation" className="option" onClick={() => handleDropdownChange('Technical Documentation')}>
                      Technical Documentation
                    </li>
                    <li data-value="Post Market Activities" className="option" onClick={() => handleDropdownChange('Post Market Activities')}>
                      Post Market Activities
                    </li>
                    <li data-value="Quality Assurance /Regulatory Affairs Service" className="option" onClick={() => handleDropdownChange('Quality Assurance /Regulatory Affairs Service')}>
                      Quality Assurance /Regulatory Affairs Service
                    </li>
                    <li data-value="Product Registrations" className="option" onClick={() => handleDropdownChange('Product Registrations')}>
                      Product Registrations
                    </li>
                    <li data-value="Digital Health Services" className="option" onClick={() => handleDropdownChange('Digital Health Services')}>
                      Digital Health Services
                    </li>
                    <li data-value="Data protection" className="option" onClick={() => handleDropdownChange('Data protection')}>
                      Data protection
                    </li>
                    <li data-value="Regulatory Life Cycle" className="option" onClick={() => handleDropdownChange('Regulatory Life Cycle')}>
                      Regulatory Life Cycle
                    </li>
                    <li data-value="Clinical Evaluation" className="option" onClick={() => handleDropdownChange('Clinical Evaluation')}>
                      Clinical Evaluation
                    </li>
                    <li data-value="Clinical Investigation" className="option" onClick={() => handleDropdownChange('Clinical Investigation')}>
                      Clinical Investigation
                    </li>
                    <li data-value="PMCF Solution" className="option" onClick={() => handleDropdownChange('PMCF Solution')}>
                      PMCF Solution
                    </li>
                    <li data-value="EU MDR/FDA/ NMPA Consulting" className="option" onClick={() => handleDropdownChange('EU MDR/FDA/ NMPA Consulting')}>
                      EU MDR/FDA/ NMPA Consulting
                    </li>
                    <li data-value="Healthcare Survey" className="option" onClick={() => handleDropdownChange('Healthcare Survey')}>
                      Healthcare Survey
                    </li>
                    <li data-value="Others" className="option" onClick={() => handleDropdownChange('Others')}>
                      Others
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12">
            <div className="appointment-2__form-input">
              <label htmlFor="message">Message</label>
              <div className="input-wrapper">
                <textarea
                  id="message"
                  placeholder="Your message..."
                  name="message"
                  value={formData.message}
                  onChange={handleInputChange}
                />
                {errors.message && <p className="error-text">{errors.message}</p>}
              </div>
            </div>
          </div>

          <div className="col-12">
            <div className="appointment-2__form-input">
              <label htmlFor="files">Choose Files</label>
              <input
                type="file"
                accept=".pdf,.doc,.docx,.txt,.png,.jpg,jpeg"
                onChange={handleFileChange}
                style={{ marginLeft: "11px", width: "97.5%" }}
            />
                <p>Drag and drop a file here or click to select a file. Maximum of 5 files with a maximum size of 25 MBs; supported formats are (DOC, DOCX, GIF, PDF, PNG, JPG, JPEG, TXT, XLS, and XLSX.)</p>

            {errors.selectedFile && <p className="error-text">{errors.selectedFile}</p>}
              
            </div>
          </div>

          <div className="col-12" >
            <button
              type="submit"
              className="rr-btn rr-btn__primary-color mt-0"
              // disabled={loading}
            >
              <span className="btn-wrap">
                <span className="text-one">
                  {loading ? 'Submitting...' : 'Submit'}
                </span>
                <span className="text-two">
                  {loading ? 'Submitting...' : 'Submit'}
                </span>
              </span>
            </button>
          </div>
        </div>
      </form>

      <div className={`result ${result?.success ? 'success' : 'error'}`}>
        {result?.message}
      </div>
      <br/><br/><br/><br/><br/><br/>
    </div>
      <div className='container' hidden>
          <div className='text-center' style={{ paddingTop: "20px" }}>
              <i>REQUEST FOR CONSULTATION</i>
              <h3>GET IN TOUCH WITH US</h3>
          </div>
          <div className='contact-form'>
              <form onSubmit={sendContactForm}>
                  <div className="row">
                      <div className="col-md-8">
                          <div className="row">
                              <div className="col-md-6 mt-4">
                                  <input
                                      type="text"
                                      placeholder='Your Name*'
                                      name='name'
                                      value={formData.name}
                                      onChange={handleInputChange}
                                      
                                  />
                                  {errors.name && <p className="error-text">{errors.name}</p>}
                              </div>
                              <div className="col-sm-12">
                                <div className="appointment-2__form-input">
                                  <label htmlFor="companyName">Company Name</label>
                                  <div className="input-wrapper">
                                    <input
                                      id="companyName"
                                      type="text"
                                      placeholder="Company name..."
                                      name="companyName"
                                    />
                                    <i className="fa-solid fa-building"></i>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6 mt-4">
                                  <input
                                      type="email"
                                      placeholder='Your Email*'
                                      name='email'
                                      value={formData.email}
                                      onChange={handleInputChange}
                                      
                                  />
                                  {errors.email && <p className="error-text">{errors.email}</p>}
                              </div>
                              <div className="col-md-6 mt-4">
                                  {/* <PhoneInput
                                      placeholder="Enter phone number*"
                                      value={phone}
                                      onChange={handlePhoneChange}
                                      
                                  /> */}
                                  {errors.phone && <p className="error-text">{errors.phone}</p>}
                              </div>
                              <div className="col-md-6 mt-4">
                                  <select
                                      className='form-select'
                                      name="consultation_type"
                                      value={formData.consultation_type}
                                      onChange={handleInputChange}
                                      
                                  >
                                      <option value="CLINICAL CONSULTATION">CLINICAL CONSULTATION</option>
                                      <option value="INTERVENTIONAL CARDIOLOGY">INTERVENTIONAL CARDIOLOGY</option>
                                      <option value="CLINICAL RESEARCH">CLINICAL RESEARCH</option>
                                      <option value="MEDICAL ACADEMIC CONSULTATION">MEDICAL ACADEMIC CONSULTATION</option>
                                      <option value="CLINICAL ULTRASOUND TRAINING">CLINICAL ULTRASOUND TRAINING</option>
                                      <option value="DENTAL CONSULTATION">DENTAL CONSULTATION</option>
                                      <option value="DIET & NUTRITIONS">DIET & NUTRITIONS</option>
                                      <option value="OTHERS">OTHERS</option>
                                  </select>
                              </div>
                              <div className="col-12 mt-4">
                                  <textarea
                                      placeholder='Your Message...'
                                      name='message'
                                      value={formData.message}
                                      onChange={handleInputChange}
                                      
                                  />
                                  {errors.message && <p className="error-text">{errors.message}</p>}
                              </div>
                              <input
                                  type="file"
                                  accept=".pdf,.doc,.docx,.txt,.png,.jpg,jpeg"
                                  onChange={handleFileChange}
                                  style={{ marginLeft: "11px", width: "97.5%" }}
                              />
                                  <p>Drag and drop a file here or click to select a file. Maximum of 5 files with a maximum size of 25 MBs; supported formats are (DOC, DOCX, GIF, PDF, PNG, JPG, JPEG, TXT, XLS, and XLSX.)</p>

                              {errors.selectedFile && <p className="error-text">{errors.selectedFile}</p>}
                          </div>
                          <button type='submit' className='btn-two w-100 mt-4'>Send Message</button>
                      </div>
                      <div className="col-md-4" style={{ marginTop: '22px' }}>
                          <div className='mt-4 p-4' style={{ border: "1px solid #dcdcdc" }}>
                              <i>Guangxi, China.</i>
                              
                          </div>
                      </div>
                  </div>
              </form>
          </div>
      </div>
    </>
  );
};

