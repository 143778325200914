import React from 'react'
import BreadcrumbArea from '../../../ShearOne/BreadcrumbArea';
import HeaderMain from '../../../Compoents/HeaderMain';
import FooterWhite from '../../../Compoents/FooterWhite';
import Appoinment from '../../Home/HomeInner/Appoinment';

export const TherapeuticSpecialties = () => {
    const breadcrumbLinks = [
        { path: '/', label: 'Home' },
        { label: 'Therapeutic Specialties', active: true }
      ];
  return (
 <>
    <HeaderMain/>
    <BreadcrumbArea 
     title="Therapeutic Specialties" 
     backgroundImage="/assets/imgs/breadcrumb/page-header-1.png" 
     links={breadcrumbLinks} 
   />
   <TherapeuticSpecialtiesAbout/>
<OurTherapeuticSpecialties/>
<Appoinment/>
<FocusPoint/>
   <FooterWhite/>
 </>
  )
}



export const TherapeuticSpecialtiesAbout = () => {
  return (
  <>
   <section class="">
   <div class="container">
     <div class="row align-items-end mb-60 mb-sm-50 mb-xs-40 py-5">
           

                <div class="col-lg-12">
                    <div class="pricing-appointment__content-right mt-md-25 mt-sm-25 mt-xs-25">
                        <h4 class="mb-0">At SQUARE MEDTECH, our therapeutic specialties encompass a wide range of medical fields, ensuring comprehensive support for Medical Device Manufacturers, Pharmaceutical & Biotech industry and Healthcare Providers according to healthcare needs. </h4>
                    </div>
                </div>
                </div>  
                      </div>
                      </section>
  </>
  )
}

export const OurTherapeuticSpecialties = () => {
  return (
    <>
            <div className='successfull-experience'>
        <div className="successfull-body container">
          <div>
            <h3> SQUARE	Therapeutic Specialties   </h3>
            <p className='text-white'>
            Solutions for Medical Device Manufacturers, Pharmaceutical & Biotech industry and Healthcare Providers:
            </p>
          </div>
          <div className="row my-4">
  <div className="col-12">
    <div className="row justify-content-between">
      <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
        <div className="succ-experi-box text-center">
          <div className="count">
            <h1>01</h1>
          </div>
          <img src="assets/imgs/slider-text/1. Cardiovascular.png" style={{ width: "85px", height: "85px", display: "inline-block" }} alt="CLINICALR" />
          <p>Cardiovascular</p>
        </div>
      </div>

      <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
        <div className="succ-experi-box text-center">
          <div className="count">
            <h1>02</h1>
          </div>
          <img src="assets/imgs/slider-text/2. Vascular.png" style={{ width: "85px", height: "85px", display: "inline-block" }} alt="coronary" />
          <p>Vascular</p>
        </div>
      </div>

      <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
        <div className="succ-experi-box text-center">
          <div className="count">
            <h1>03</h1>
          </div>
          <img src="assets/imgs/slider-text/3. Medical Imaging.png" style={{ width: "85px", height: "85px", display: "inline-block" }} alt="precutaneous" />
          <p>Medical Imaging</p>
        </div>
      </div>

      <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
        <div className="succ-experi-box text-center">
          <div className="count">
            <h1>04</h1>
          </div>
          <img src="assets/imgs/slider-text/4. Oral Care.png" style={{ width: "85px", height: "85px", display: "inline-block" }} alt="coronary" />
          <p>Oral care</p>
        </div>
      </div>


    </div>

    <div className="row justify-content-between">
    <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
        <div className="succ-experi-box text-center">
          <div className="count">
            <h1>05</h1>
          </div>
          <img src="assets/imgs/slider-text/5. Surgical.png" style={{ width: "85px", height: "85px", display: "inline-block" }} alt="pacemaker" />
          <p>Surgical</p>
        </div>
      </div>
      <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
        <div className="succ-experi-box text-center">
          <div className="count">
            <h1>06</h1>
          </div>
          <img src="assets/imgs/slider-text/6. Orthopedics.png" style={{ width: "85px", height: "85px", display: "inline-block" }} alt="CLINICALR" />
          <p>Orthopedics</p>
        </div>
      </div>

      <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
        <div className="succ-experi-box text-center">
          <div className="count">
            <h1>07</h1>
          </div>
          <img src="assets/imgs/slider-text/7. Respiratory Care.png" style={{ width: "85px", height: "85px", display: "inline-block" }} alt="coronary" />
          <p>Respiratory Care</p>
        </div>
      </div>

      <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
        <div className="succ-experi-box text-center">
          <div className="count">
            <h1>08</h1>
          </div>
          <img src="assets/imgs/slider-text/8. Wound Care.png" style={{ width: "85px", height: "85px", display: "inline-block" }} alt="precutaneous" />
          <p>Wound Care</p>
        </div>
      </div>

      
    </div>

    <div className="row justify-content-between">
    <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
        <div className="succ-experi-box text-center">
          <div className="count">
            <h1>09</h1>
          </div>
          <img src="assets/imgs/slider-text/9. Oncology.png" style={{ width: "85px", height: "85px", display: "inline-block" }} alt="coronary" />
          <p>Oncology</p>
        </div>
      </div>

      <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
        <div className="succ-experi-box text-center">
          <div className="count">
            <h1>10</h1>
          </div>
          <img src="assets/imgs/slider-text/10. Neurology.png" style={{ width: "85px", height: "85px", display: "inline-block" }} alt="pacemaker" />
          <p>Neurology</p>
        </div>
      </div>
      <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
        <div className="succ-experi-box text-center">
          <div className="count">
            <h1>11</h1>
          </div>
          <img src="assets/imgs/slider-text/11. Nephrology.png" style={{ width: "85px", height: "85px", display: "inline-block" }} alt="CLINICALR" />
          <p>Nephrology</p>
        </div>
      </div>

      <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
        <div className="succ-experi-box text-center">
          <div className="count">
            <h1>12</h1>
          </div>
          <img src="assets/imgs/slider-text/12. Urology.png" style={{ width: "85px", height: "85px", display: "inline-block" }} alt="coronary" />
          <p>Urology</p>
        </div>
      </div>

      <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
        <div className="succ-experi-box text-center">
          <div className="count">
            <h1>13</h1>
          </div>
          <img src="assets/imgs/slider-text/13. Vestibular.png" style={{ width: "85px", height: "85px", display: "inline-block" }} alt="precutaneous" />
          <p>Vestibular</p>
        </div>
      </div>

      <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
        <div className="succ-experi-box text-center">
          <div className="count">
            <h1>14</h1>
          </div>
          <img src="assets/imgs/slider-text/14. Temperature Device.png" style={{ width: "85px", height: "85px", display: "inline-block" }} alt="coronary" />
          <p>Temperature Management</p>
        </div>
      </div>

      <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
        <div className="succ-experi-box text-center">
          <div className="count">
            <h1>15</h1>
          </div>
          <img src="assets/imgs/slider-text/15. Pharmaceutical & Biotech.png" style={{ width: "85px", height: "85px", display: "inline-block" }} alt="pacemaker" />
          <p>Pharmaceuticals  </p>
        </div>
      </div>
      <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
        <div className="succ-experi-box text-center">
          <div className="count">
            <h1>16</h1>
          </div>
          <img src="assets/imgs/slider-text/Biotech.png" style={{ width: "85px", height: "85px", display: "inline-block" }} alt="pacemaker" />
          <p>Biotech </p>
        </div>
      </div>
    </div>
  </div>
</div>

          {/* <p className='text-white'>
          Our expertise ensures tailored solutions for each specialty, optimizing patient outcomes and enhancing clinical practices. We stay at the forefront of medical advancements to provide cutting-edge consulting services.
          </p> */}
        </div>
      </div>
    </>
  )
}

export const FocusPoint = () => {
    return (
      <>
          <section class="footer__cta footer__cta-bottom-up">
      <div class="container">
          <div class="row mb-minus-30">
              <div class="col-md-6">
                  <div class="footer__cta-item mb-30 theme-bg-primary d-flex flex-lg-row flex-column align-items-lg-center">
                      <div class="footer__cta-item-icon d-flex align-items-center justify-content-center">
                          <img class="img-fluid" src="assets/imgs/footer__cta/footer__cta-item-1.png" alt="icon not found"/>
                      </div>
  
                      <div class="footer__cta-item-text">
                          <h4 class="color-white mb-15 mb-10">We Provide Solutions for: </h4>
                          <ul class="color-white mb-0">
                                <li>Medical Device Manufacturers</li>
                                <li>Pharmaceutical & Biotech industry</li>
                                <li>Healthcare Providers</li>
                            </ul>

  
                      </div>
                  </div>
              </div>
  
              <div class="col-md-6">
                  <div class="footer__cta-item mb-30 theme-bg-glow d-flex flex-lg-row flex-column align-items-lg-center">
                      <div class="footer__cta-item-icon d-flex align-items-center justify-content-center">
                          <img class="img-fluid" src="assets/imgs/footer__cta/footer__cta-item-2.png" alt="icon not found"/>
                      </div>
  
                      <div class="footer__cta-item-text">
                          <h4 class="color-white mb-15 mb-10">Our Expertise: </h4>
                          <ul class="color-white mb-0">
                                <li>Ensures solutions for each specialty</li>
                                <li>Optimizing patient outcomes</li>
                                <li>Cutting-edge consulting services</li>
                            </ul>

  
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </section>
      </>
    )
  }
